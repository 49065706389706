<script>
import feather from 'feather-icons';
import Flicking from "@egjs/vue3-flicking";


export default {
	components: { 
		Flicking: Flicking
		
	},
	data: () => {
		return {
			projectsHeading: 'Projects Portfolio',
			selectedCategory: '',
			searchProject: '',
			valImg: [
				'/images/rendusMy3dplanner/04.webp', 
				'/images/rendusMy3dplanner/02.webp', 
				'/images/rendusMy3dplanner/01.webp',
				'/images/rendusMy3dplanner/05.webp',
				'/images/rendusMy3dplanner/08.webp',
				'/images/rendusMy3dplanner/07.webp',
				'/images/rendusMy3dplanner/09.webp',
				'/images/rendusMy3dplanner/10.webp',
			]
		};
	},
	computed: {
		// Get the filtered projects
		filteredProjects() {
			if (this.selectedCategory) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		// Filter projects by category
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter projects by title search
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
		nextImage() {
			this.$refs.flick.next();

		},
		prevImage() {
			this.$refs.flick.prev();

		}
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- Projects grid -->
	<section class="pt-14 sm:pt-14 pb-14 sm:pb-14 bg-my3dp-black">
		<div class="triangle">
			<img src="@/assets/images/rectangle_page.png" alt="Logo Big" width="57" height="28">
		</div>
        <div class="w-[90%] max-w-[1024px] m-auto text-center text-my3dp-white">
		<!-- Projects grid title -->
		<div class="text-center text-my3dp-white w-[90%] max-w-[1024px] m-auto" >
			<h2>
			<p
				class="font-roboto-light text-3xl sm:text-3xl mb-3 text-my3dp-WHITE"
            >
				{{ $t('message.home_carroussel_titre') }}
			</p>
			</h2>
			
			
			<div class="bkg-my3dp-white">
				<Flicking class="w-[100%] lg:w-[768px] mx-auto" ref="flick" :options="{ align: 'center', autoResize: true, circular : true }">
					<div
						v-for="(item, idx) in valImg"
						:key="idx"
						class="w-[768px] h-[576px]"
					>
						<img :src="item" key="idx" width="768" :alt="$t('message.home_carroussel_alt1')" height="576">
					</div>

					<!-- <div class="w-[768px] h-[576px]" key="contImg01"> -->
						<!-- <img src="@/assets/images/rendusMy3dplanner/04.png" key="img01" :alt="$t('message.home_carroussel_alt1')" width="768" height="576"> -->
					<!-- </div> -->
					<!-- <div class="w-[768px] h-[576px]" key="contImg02"> -->
						<!-- <img src="@/assets/images/rendusMy3dplanner/02.png" key="img02" :alt="$t('message.home_carroussel_alt2')" width="768" height="576"> -->
					<!-- </div> -->
					<!-- <div class="w-[768px] h-[576px]" key="contImg03"> -->
						<!-- <img src="@/assets/images/rendusMy3dplanner/01.png" key="img03" :alt="$t('message.home_carroussel_alt3')" width="768" height="576"> -->
					<!-- </div> -->
					<!-- <div class="w-[768px] h-[576px]" key="contImg04"> -->
						<!-- <img src="@/assets/images/rendusMy3dplanner/05.png" key="img04" :alt="$t('message.home_carroussel_alt4')" width="768" height="576"> -->
					<!-- </div> -->
					<!-- <div class="w-[768px] h-[576px]" key="contImg05"> -->
						<!-- <img src="@/assets/images/rendusMy3dplanner/08.png" key="img05" :alt="$t('message.home_carroussel_alt5')" width="768" height="576"> -->
					<!-- </div> -->
					<!-- <div class="w-[768px] h-[576px]" key="contImg06"> -->
						<!-- <img src="@/assets/images/rendusMy3dplanner/07.png" key="img06" :alt="$t('message.home_carroussel_alt6')" width="768" height="576"> -->
					<!-- </div> -->
					<!-- <div class="w-[768px] h-[576px]" key="contImg07"> -->
						<!-- <img src="@/assets/images/rendusMy3dplanner/09.png" key="img07" :alt="$t('message.home_carroussel_alt7')" width="768" height="576"> -->
					<!-- </div> -->
					<!-- <div class="w-[768px] h-[576px]" key="contImg01"> -->
						<!-- <img src="@/assets/images/rendusMy3dplanner/10.png" key="img01" :alt="$t('message.home_carroussel_alt8')" width="768" height="576"> -->
					<!-- </div> -->
				</Flicking>
				<p class="font-roboto-regular m-2">
					{{ $t('message.home_carroussel_legende') }}
				</p>
				<div @click="prevImage" class="inline-block mt-4 mr-2">
					<i class="inline-block ml-1 w-8 h-8 text-my3dp-white" data-feather="arrow-left-circle"></i>
				</div>
				<div @click="nextImage" class="inline-block mt-4 ml-2">
					<i class="inline-block ml-1 w-8 h-8 text-my3dp-white" data-feather="arrow-right-circle"></i>
				</div>
			</div>
		</div>
		</div>
	</section>
</template>

<style scoped>
.triangle {
	position: absolute;
    margin: auto;
    margin-left: calc(50% - 29px);
    margin-top: -3.5rem;
}
</style>
