<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'MY3DPLANNER',
			author: 'cadelog',
		};
	},
};
</script>

<template>
	<div class="flex-none text-md sm:text-base justify-end items-center text-center max-w-[80%]">
		<div
			class="font-roboto-regular text-base text-my3dp-white mr-50"
		>
			<a
				href="contact"
				target="_self"
				class="font-roboto-regular text-sm hover:underline hover:text-my3dp-yellow duration-500"
			>
			
			{{ $t('message.footer_contact') }}
			</a> | 
			<a
				href="mentions"
				target="_self"
				class="font-roboto-regular text-sm hover:underline hover:text-my3dp-yellow duration-500"
			>
			{{ $t('message.footer_mention') }}
			</a> | 
			<a
				href="cgv"
				target="_self"
				class="font-roboto-regular text-sm hover:underline hover:text-my3dp-yellow duration-500"
			>
			{{ $t('message.cgv_link') }}
			</a> | 
			<a
				href="https://www.my3dplanner.com"
				target="_self"
				class="font-roboto-medium hover:underline hover:text-my3dp-yellow duration-500"
			>
			&copy; {{ projectName }}
			</a>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
