<script>
import feather from 'feather-icons';
import FooterCopyright from './FooterCopyright.vue';
import { socialLinks } from '../../data/socialLinks';

export default {
	components: { FooterCopyright },
	data() {
		return {
			socials: socialLinks,
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div class="w-full bg-my3dp-darkBlue sticky top-[100vh]">
		<div class="container mx-auto">
			<div
				class="pt-6 sm:pt-6 pb-1 mt-0 bg-my3dp-darkBlue"
			>
			<div class="flex justify-center items-center mb-6 sm:mb-6 forceMaxW">
				<div class="flex-none">
					<img class="hidden sm:block" src="@/assets/images/logo_my3dplanner.webp" alt="Logo Big" width="250" height="71.81">
					<img class="block sm:hidden" src="@/assets/images/logo_my3dplanner-small.webp" alt="Logo Small" width="45" height="59.2">
				</div>
				<div class="flex-auto ">
				</div>

				<FooterCopyright />
			</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.forceFull {
	/* padding: 0; */
}
.forceMaxW {
	/* width: 100vw !important; */
}
.forceFullContent {
	/* max-width: 1024px; */
}
</style>
