<script>
export default {
	props: {
		lang: {
			type: String,
			required: true,
		},
	},
	methods: {
		toggleLang() {
			const newLang = this.lang === 'fr' ? 'en' : 'fr';
			console.log("newLang", newLang, this.lang);
			localStorage.setItem('lang', newLang);
			this.$i18n.locale = newLang
			this.$emit('lang-changed', newLang);
			// this.$router.go();
		},
	},
};
</script>

<template>
	
	<a href="#" @click.prevent="toggleLang" aria-label="Changez la langue français ou anglais" class="w-10">
		<span
			v-if="lang === 'fr'"
			class="text-my3dp-blue hover:text-my3dp-yellow w-5"
		>FR</span>
		<span
			v-else
			class="text-my3dp-blue hover:text-my3dp-yellow w-5"
		>EN</span>
	</a>
</template>
