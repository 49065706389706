<script>
import feather from 'feather-icons';

export default {
	components: { },
	data: () => {
		return {
			// projects,
			projectsHeading: 'Projects Portfolio',
			selectedCategory: '',
			searchProject: '',
		};
	},
	computed: {
		// Get the filtered projects
		filteredProjects() {
			if (this.selectedCategory) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		// Filter projects by category
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter projects by title search
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- section -->
	<section class="pt-14 sm:pt-14 pb-14 sm:pb-14 bg-my3dp-blue">
        <div class="triangle">
			<img src="@/assets/images/rectangle_page.png" alt="Logo Big" width="57" height="28">
		</div>
		<!-- Width container -->
		<div class="w-[90%] max-w-[1024px] m-auto text-center text-my3dp-white">
			<!-- Content -->
			<div class="text-center text-my3dp-white">
				<h2>
					<p
						class="font-roboto-light text-3xl sm:text-3xl mb-3 text-my3dp-white"
					>
						{{ $t('message.home_presentation') }}
					</p>
				</h2>
				<p>
					<span class="font-roboto-bold">{{ $t('message.home_presentation_txt01') }}</span>
					{{ $t('message.home_presentation_txt02') }}
					<span class="font-roboto-bold">{{ $t('message.home_presentation_txt03') }}</span>
					{{ $t('message.home_presentation_txt04') }}
					<span class="font-roboto-bold">{{ $t('message.home_presentation_txt05') }}</span>
					{{ $t('message.home_presentation_txt06') }}
					
				</p>
				<p>
					<span class="font-roboto-bold">{{ $t('message.home_presentation_txt07') }}</span>
					{{ $t('message.home_presentation_txt08') }}
					<span class="font-roboto-bold">{{ $t('message.home_presentation_txt09') }}</span>
					{{ $t('message.home_presentation_txt10') }}
					<br />
					<span class="font-roboto-bold">{{ $t('message.home_presentation_txt11') }}</span>
					{{ $t('message.home_presentation_txt12') }}
					<span class="font-roboto-bold">{{ $t('message.home_presentation_txt13') }}</span>
				</p>
			</div>
			<!-- Icones -->
			<div class="contenairCase mt-4">
				<div class="caseDetail">
					<div>
						<img src="@/assets/images/icone_chambre.webp" alt="" width="200" height="200">
						<div class="font-roboto-bold my-4">{{ $t('message.home_presentation_chambre') }}</div>
					</div>
				</div>
				<div class="caseDetail">
					<div><img src="@/assets/images/icone_salon.webp" alt="" width="200" height="200">
						<div class="font-roboto-bold my-4">{{ $t('message.home_presentation_salon') }}</div>
					</div>
				</div>
				<div class="caseDetail">
					<div><img src="@/assets/images/icone_salledebain.webp" alt="" width="200" height="200">
						<div class="font-roboto-bold my-4">{{ $t('message.home_presentation_sdb') }}</div>
					</div>
				</div>
				<div class="caseDetail">
					<div><img src="@/assets/images/icone_cuisine.webp" alt="" width="200" height="200">
						<div class="font-roboto-bold my-4">{{ $t('message.home_presentation_cuisine') }}</div>
					</div>
				</div>
				<div class="caseDetail">
					<div><img src="@/assets/images/icone_autre.webp" alt="" width="200" height="200">
						<div class="font-roboto-bold my-4">{{ $t('message.home_presentation_autre') }}</div>
					</div>
				</div>
			</div>

		</div>

        <!-- Background image -->
		<div class="logosBkg">
		</div>

	</section>
</template>

<style scoped>
.triangle {
	position: absolute;
    margin: auto;
    margin-left: calc(50% - 29px);
    margin-top: -3.5rem;
}

.logosBkg {
	margin-bottom: -56px;
	width: 100%;
	height: 178px;
	overflow: hidden;
	background: url("@/assets/images/logos.webp") no-repeat;
	background-position: center top;
}

.logosBkg2 {
	width: 821px;
	height: 178px;
	padding: 0px;
	overflow: hidden;
}
.logosBkg2 img {
	width: 100%;
	height: 100%;
	object-fit: cover; 
}
.contenairCase {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}
.caseDetail {
	display: inline-flex;
	padding: 20px;
	width: 200px;
}
@media screen and (max-width: 768px) {
	.caseDetail {
		width: 160px;
	}
}
</style>
