<script>
import LangSwitcher from '../LangSwitcher';
// import HireMeModal from '../HireMeModal.vue';
import feather from 'feather-icons';
import AppHeaderLinks from './AppHeaderLinks.vue';
import Button from '../reusable/Button.vue';

export default {
	components: {
		LangSwitcher,
		// HireMeModal,
		AppHeaderLinks,
		Button,
	},
	data() {
		return {
			isOpen: false,
			theme: '',
			lang: '',
			modal: false,
			categories: [
				{
					id: 1,
					value: 'web',
					name: 'Web Application',
				},
				{
					id: 2,
					value: 'mobile',
					name: 'Mobile Application',
				},
				{
					id: 3,
					value: 'ui-ux',
					name: 'UI/UX Design',
				},
				{
					id: 4,
					value: 'branding',
					name: 'Branding & Anim',
				},
			],
		};
	},

	created() {
		this.theme = localStorage.getItem('theme') || 'light';
		this.lang = localStorage.getItem('lang') || 'fr';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
		this.lang = localStorage.getItem('lang') || 'fr';
	},
	methods: {
		updateTheme(theme) {
			this.theme = theme;
		},
		updateLang(lang) {
			this.lang = lang;
		},
		showModal() {
			if (this.modal) {
				// Stop screen scrolling
				document
					.getElementsByTagName('html')[0]
					.classList.remove('overflow-y-hidden');
				this.modal = false;
			} else {
				document
					.getElementsByTagName('html')[0]
					.classList.add('overflow-y-hidden');
				this.modal = true;
			}
		},
		goToApp () {
			// window.location.href = 'https://app.my3dplanner.com/';
			if ( this.$i18n.locale === 'en' ) {
				window.location.href = 'https://app.my3dplanner.com/?lang=en';
			}else{
				window.location.href = 'https://app.my3dplanner.com/';
			}
		}
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<nav id="nav" class="sm:container sm:mx-auto">
		<!-- Header start -->
		<div
			class="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center my-6"
		>
			<!-- Header menu links and small screen hamburger menu -->
			<!-- @click="$router.push('/')"  -->
			<div 
			@click="$router.replace( $i18n.locale == 'en' ? '/en' : '/' )"
				class="flex justify-between items-center px-4 sm:px-0 cursor-pointer"
			>
				<div class="">
					<img  src="@/assets/images/logo_my3dplanner.webp" alt="Logo Big" width="250" height="71.81">
				</div>

				<!-- Theme switcher small screen -->
				<lang-switcher
					:lang="lang"
					@LangChanged="updateLang"
					class="block mr-[0px] sm:hidden bg-my3dp-white hover:bg-hover-light hover:shadow-sm rounded-lg"
					/>
					<!-- class="block sm:hidden bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2.5 py-2 rounded-lg" -->
					<!-- class="block sm:hidden bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2.5 py-2 rounded-lg" -->

				<!-- Small screen hamburger menu -->
				<div class="sm:hidden mr-6">
					<button
						@click="isOpen = !isOpen"
						type="button"
						class="focus:outline-none"
						aria-label="Hamburger Menu"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							class="h-7 w-7 fill-current text-secondary-dark dark:text-ternary-light"
						>
							<path
								v-if="isOpen"
								fill-rule="evenodd"
								d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
								clip-rule="evenodd"
							></path>
							<path
								v-if="!isOpen"
								fill-rule="evenodd"
								d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
							></path>
						</svg>
					</button>
				</div>
			</div>

			<!-- Header links -->
			<AppHeaderLinks :showModal="showModal" :isOpen="isOpen" />
			

			<!-- Header right section buttons -->
			<div
				class="hidden sm:flex justify-between items-center flex-col md:flex-row"
			>
				<!-- Hire me button -->	
				<div class="hidden lg:block">
					<!-- @click="$router.push('my3dplanner')" -->
					<Button
						:title="$t('message.menu_essayez')"
						class="text-md font-roboto-light bg-my3dp-yellow hover:bg-my3dp-blue text-my3dp-black hover:text-my3dp-white shadow-sm rounded-lg px-5 py-2.5 duration-300"
						@click="goToApp"
						aria-label="Essayez moi"
					/>
				</div>
				
			
				<!-- Theme switcher large screen -->
				<lang-switcher
					:lang="lang"
					@LangChanged="updateLang"
					class="ml-8 bg-my3d-white px-3 py-2 cursor-pointer"
				/>
					<!-- class="ml-8 bg-my3d-white dark:bg-ternary-dark px-3 py-2 shadow-sm rounded-xl cursor-pointer" -->
			</div>
		</div>

		<!-- Hire me modal -->
		<!-- <HireMeModal -->
			<!-- :showModal="showModal" -->
			<!-- :modal="modal" -->
			<!-- :categories="categories" -->
			<!-- aria-modal="Hire Me Modal" -->
		<!-- /> -->
	</nav>
</template>

<style scoped>
#nav a.router-link-exact-active {
	@apply text-indigo-700;
	@apply dark:text-indigo-400;
	@apply font-medium;
}
</style>
