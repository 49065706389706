import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
// import i18n from '@/i18n';
// import { i18n } from '@/i18n';
// import i18n from '@/i18n';

const routes = [
	{
		path: '/',
		alias: ['/FR/'],
		name: 'Home',
		component: Home,
		meta: {
			title: 'Logiciel d\'aménagement intérieur 2D/3D en ligne gratuit',
			description: 'MY3DPLANNER est un logiciel d\'architecture en ligne. Concevez, meublez, personnalisez et visualisez rapidement vos projets, directement dans votre navigateur.',
		},
	},
	// {
		// path: "/:notFound",
		// component: Home,
	// },
	{
		path: '/:pathMatch(.*)*',
		//  component: PathNotFound 
		component: Home,
		meta: {
			title: 'Logiciel d\'aménagement intérieur 2D/3D en ligne gratuit',
			description: 'MY3DPLANNER est un logiciel d\'architecture en ligne. Concevez, meublez, personnalisez et visualisez rapidement vos projets, directement dans votre navigateur.',
		},
	},
	{
		path: '/',
		alias: ['/en'],
		name: 'Home',
		component: Home,
		meta: {
			title: 'Free online 2D/3D interior design software',
			description: 'MY3DPLANNER is online architecture software. Quickly design, furnish, personalize and visualize your projects, directly in your browser.',
		},
	},
	{
		path: '/',
		beforeEnter() {location.href = 'https://app.my3dplanner.com'},
		alias: ['/my3dplanner', '/en/my3dplanner'],
		name: 'My3dplanner - Application',
	},
	{
		path: '/tarifs',
		name: 'Tarifs',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Tarifs.vue'),
		meta: {
			title: 'Tarifs du Logiciel d\'Aménagement 2D/3D en Ligne - Découvrez nos Offres',
			description: 'Découvrez nos tarifs et offres pour le logiciel d\'aménagement 2D/3D en ligne de MY3DPLANNER. Explorez nos options d\'abonnement mensuel et annuel, ainsi que nos fonctionnalités, crédits, et avantages. Trouvez la solution qui convient le mieux à vos besoins et à votre budget.',
		},
	},
	{
		path: '/tarifs',
		name: 'Tarifs',
		alias: ['/en/prices'],
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Tarifs.vue'),
		meta: {
			title: 'Prices for Online 2D/3D Planning Software - Discover our Offers',
			description: 'Discover our prices and offers for the online 2D/3D planning software from MY3DPLANNER. Explore our monthly and annual subscription options, as well as our features, credits, and benefits. Find the solution that best suits your needs and budget.',
		},
	},
	{
		path: '/solutions',
		name: 'Solutions',
		alias: ['/app/', '/app'],
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Service.vue'),
		meta: {
			title: 'Logiciel d\'aménagement intérieur 2D/3D en ligne - Découvrez nos Solutions',
			description: 'Découvrez nos solutions de logiciel d\'architecture en ligne pour la conception 2D/3D de vos espaces intérieurs. Créez, personnalisez et visualisez rapidement vos projets d\'aménagement grâce à MY3DPLANNER. Transformez vos idées en réalité et générez des images grâce à l\'intelligence artificielle (IA).',
		},
	},
	{
		path: '/solutions',
		name: 'Solutions',
		alias: ['/en/solutions'],
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Service.vue'),
		meta: {
			title: 'Online 2D/3D interior design software - Discover our Solutions',
			description: 'Discover our online architectural software solutions for the 2D/3D design of your interior spaces. Quickly create, personalize and visualize your planning projects with MY3DPLANNER. Turn your ideas into reality and generate images using artificial intelligence (AI).',
		},
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Contact.vue'),
		meta: {
			title: 'Contactez MY3DPLANNER - Service Client et Commercial',
			description: 'Besoin d\'assistance ou d\'informations supplémentaires ? Contactez le service client de MY3DPLANNER. Notre équipe est là pour répondre à toutes vos questions, demandes d\'assistance technique ou informations générales. N\'hésitez pas à nous contacter via le formulaire de contact ou en utilisant les coordonnées fournies.',
		},
	},
	{
		path: '/contact',
		name: 'Contact',
		alias: ['/en/contact'],
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Contact.vue'),
		meta: {
			title: 'Contact MY3DPLANNER - Customer and Sales Service',
			description: 'Need assistance or additional information? Contact MY3DPLANNER customer service. Our team is here to answer all your questions, requests for technical assistance or general information. Do not hesitate to contact us via the contact form or using the contact details provided.',
		},
	},
	{
		path: '/mentions',
		name: 'Mentions',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Mentions.vue'),
		meta: {
			title: 'My3dplanner - Mentions légales',
		},
	},
	{
		path: '/mentions',
		name: 'Mentions',
		alias: ['/en/mentions'],
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Mentions.vue'),
		meta: {
			title: 'My3dplanner - Mentions légales',
		},
	},
	{
		path: '/mentions',
		name: 'Mentions',
		alias: ['/en/legal'],
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Mentions.vue'),
		meta: {
			title: 'My3dplanner - Legal notice',
		},
	},
	{
		path: '/cgv',
		name: 'Cgv',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Cgv.vue'),
		meta: {
			title: 'My3dplanner - Conditions générales de vente',
		},
	},
	{
		path: '/cgv',
		name: 'Cgv',
		alias: ['/en/cgv'],
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Cgv.vue'),
		meta: {
			title: 'My3dplanner - Terms and Conditions of Sale',
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		document.getElementById('app').scrollIntoView();
	},
});

export default router;

/**
 * Below code will display the component/active page title
 * Powered by: MY3DPLANNER
 */








// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
	// `/nested`'s will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	const nearestWithDescription = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.description);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
		
		// const DEFAULT_TITLE = nearestWithTitle.meta.title;
		// let ff = $t('message.solution_tarif_zone1_4')//i18n.t('message.solution_tarif_zone1_4')
		// console.log("ff", i18n)
		// document.title = i18n.t(to.meta.title || DEFAULT_TITLE);
	} else if (previousNearestWithMeta) {
		document.title = previousNearestWithMeta.meta.title;
		
		// const DEFAULT_TITLE = nearestWithTitle.meta.title;
		// let ff = i18n.t('message.solution_tarif_zone1_4')
		// console.log("ff", i18n)
		// document.title = i18n.t(to.meta.title || DEFAULT_TITLE);
	}

	if (nearestWithDescription) {
		document.querySelector('meta[name="description"]').setAttribute('content', nearestWithDescription.meta.description)
		//document.description = nearestWithDescription.meta.description;
	} else if (previousNearestWithMeta) {
		document.querySelector('meta[name="description"]').setAttribute('content', previousNearestWithMeta.meta.description)
		// document.description = previousNearestWithMeta.meta.description;
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(
		document.querySelectorAll('[data-vue-router-controlled]')
	).map((el) => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create so we don't interfere with other ones.
			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	next();
});
