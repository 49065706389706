<template>
    <section class='pt-14 sm:pt-14 pb-14 sm:pb-14 bg-my3dp-blue'>
        <h2>
        <p
            class="font-roboto-light text-3xl sm:text-3xl mb-3 text-my3dp-white"
        >
            {{ $t('message.home_tileFaq') }}
        </p>
        </h2>
        <div class='accordion'>
            <div
                class='accordion__item'
                v-for='(item, i) in items'
                :key='`accordion-item-${i}`'
            >
                <div
                    class='accordion__title'
                    :class='{ "accordion__title_active": activeIndex === i }'
                    @click='activeIndex = activeIndex === i ? null : i'
                >
                    <span
                        class='accordion__arrow'
                        :class='{ "accordion__arrow_active": activeIndex === i }'
                    ></span>
                    <span v-if="this.$i18n.locale === 'en'">{{ item.titleEn }}</span>
                    <span v-else>{{ item.title }}</span>
                </div>
                <div class='accordion__value' v-show='i === activeIndex'>
                    <span v-if="this.$i18n.locale === 'en'">{{ item.valueEn }}</span>
                    <span v-else>{{ item.value }}</span>
                    
                </div>
            </div>
        </div>
    </section>
    </template>
    
    <script>
      export default {
        name: 'Accordion',
        props: { items: { type: Array, required: true } },
        data: () => ({ activeIndex: null })
      }
    </script>
    
    <style>
    :root {
        --font-color: #000;
        --border-color: #9e9e9e;
        --active-color: #d50000;
    }
    .accordion
    {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 100%;
        max-width: 625px;
        border-radius: 5px;
        border-bottom: 0px solid;
        /* border: thin solid var(; */
        box-sizing: border-box;
        overflow: hidden;
    }
    .accordion__item {
        margin: 0;
        padding: 0;
        border-bottom: 0px solid;
        /* border-bottom: thin solid var(--bor */
        box-sizing: border-box;
    }
    .accordion__item &:last-child {
    /* &:last-child */
        border-bottom: none
    }
    .accordion__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 1em 1.5em;
        color: #FFFFFF;
        transition: all .3s;
        box-sizing: border-box;
        font-size: 1.1em;
        text-align: left;
    }
    .accordion__title &:hover {
    /* &:hover */
        color: var(--active-color)
    }

    .accordion__title .accordion__arrow
        {
            position: relative;
            margin: 0;
            margin-right: 1em;
            padding: 0;
            width: 0.8em;
            height: 0.8em;
            box-sizing: border-box;
            border: solid white;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 1px;
            transform: rotate(45deg) translate(0, -50%);

        }
        .accordion__arrow
        &:after {
           content: '';
           position: absolute;
           top: 50%;
           left: 0;
           width: .65em;
           height: .65em;
           border-width: 1px 1px 0 0;
           border-style: solid;
           border-color: initial;
           transform: rotate(45deg) trans;
           transform-origin: top;
           transition: transform .3s ease;
           box-sizing: border-box;
        }
        /* .accordion__arrow */
        .accordion__title .accordion__arrow_active {
            /* transform: rotate(135deg) translate(0, -50%); */
            transform: rotate(-45deg) translate(0%,20%);;
            transition: transform 0.2s linear;
        }
        .accordion__value
        {
            margin: 0;
            padding: 1em 1.5em;
            font-size: .95em;
            text-align: left;
            color: #FFFFFF;
            /* rgba(var(--font-c; */
            background: #ffffff20;
            /* background: #fafafa10; */
            box-sizing: border-box;
        }
    </style>
    
    
    <!-- <style scoped>
    .accordion
        display: block
        margin: 0 auto
        padding: 0
        width: 100%
        max-width: 625px
        border-radius: 5px
        border: thin solid var(--border-color)
        box-sizing: border-box
        overflow: hidden
    
    .accordion__item
        margin: 0
        padding: 0
        border-bottom: thin solid var(--border-color)
        box-sizing: border-box
    
        &:last-child
            border-bottom: none
    
    .accordion__title
        display: flex
        justify-content: flex-start
        align-items: center
        cursor: pointer
        margin: 0
        padding: 1em 1.5em
        color: var(--font-color)
        transition: all .3s
        box-sizing: border-box
    
        &:hover
            color: var(--active-color)
    
    .accordion__arrow
        position: relative
        margin: 0
        margin-right: 1em
        padding: 0
        width: 1em
        height: 1em
        box-sizing: border-box
    
        &:after
            content: ''
            position: absolute
            top: 50%
            left: 0
            width: .65em
            height: .65em
            border-width: 1px 1px 0 0
            border-style: solid
            border-color: initial
            transform: rotate(45deg) translate(0, -50%)
            transform-origin: top
            transition: transform .3s ease-out
            box-sizing: border-box
    
        &_active:after
            transform: rotate(135deg) translate(0, -50%)
    
    .accordion__value
        margin: 0
        padding: 1em 1.5em
        font-size: .95em
        text-align: left
        color: rgba(var(--font-color), .8)
        background: #fafafa
        box-sizing: border-box
    </style>
    -->