<script>
import feather from 'feather-icons';

export default {
	components: { },
	data: () => {
		return {
			// projects,
			projectsHeading: 'Projects Portfolio',
			selectedCategory: '',
			searchProject: '',
		};
	},
	computed: {
		// Get the filtered projects
		filteredProjects() {
			if (this.selectedCategory) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		// Filter projects by category
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter projects by title search
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- Projects grid -->
	<section class="pt-14 sm:pt-14 pb-14 sm:pb-14 bg-my3dp-blue">
		
		
		<div class="triangle">
			<img src="@/assets/images/rectangle_page.png" alt="Logo Big" width="57" height="28">
		</div>
		
		<!-- Background image -->
		<div class="logosBkgBottom">
		</div>
		
		<!-- Projects grid title -->
		<div class="w-[90%] max-w-[1024px] m-auto text-center text-my3dp-white">
			<h2>
			<p
				class="font-roboto-light text-3xl sm:text-3xl mb-3 text-my3dp-white"
                >
				{{ $t('message.home_perso_titre') }} 
			</p>
			</h2>
            <p>
				{{ $t('message.home_perso_content01') }} 
				<span class="font-roboto-bold">{{ $t('message.home_perso_content02') }}</span>
				{{ $t('message.home_perso_content03') }} 
				<br />
				{{ $t('message.home_perso_content04') }} 
				<br />
				{{ $t('message.home_perso_content05') }}
				<span class="font-roboto-bold">{{ $t('message.home_perso_content06') }}</span>
				{{ $t('message.home_perso_content07') }} 
				<span class="font-roboto-bold">{{ $t('message.home_perso_content08') }}</span>
				{{ $t('message.home_perso_content09') }} 
				<br>
				{{ $t('message.home_perso_content10') }} 
				<span class="font-roboto-bold">{{ $t('message.home_perso_content11') }}</span> {{ $t('message.home_perso_content12') }}
				<br />
				{{ $t('message.home_perso_content13') }} 
			</p>	
            
			<button 
				class="inline-block bg-my3dp-black hover:bg-my3dp-darkBlue text-white font-bold py-2 md:py-3 px-3 md:px-4 mt-10 rounded-full text-sm md:text-base"
				@click="$router.replace( $i18n.locale == 'en' ? '/en/contact' : '/contact' )"
				>
				
				{{ $t('message.home_perso_contentBt') }} 
				<i class="inline-block ml-1 w-8 h-8" data-feather="arrow-right-circle"></i>
			</button>
		</div>


	</section>
</template>

<style scoped>

.triangle {
	position: absolute;
    margin: auto;
    margin-left: calc(50% - 29px);
    margin-top: -3.5rem;
}

.logosBkgBottom {
	margin-top: -56px;
	width: 100%;
	height: 279px;
	overflow: hidden;
	background: url("@/assets/images/logosy.webp") no-repeat;
	background-position: center bottom;
}

</style>
