<script>
import feather from 'feather-icons';

export default {
	components: { },
	data: () => {
		return {
			projectsHeading: 'Projects Portfolio',
			selectedCategory: '',
			searchProject: '',
		};
	},
	computed: {
		// Get the filtered projects
		filteredProjects() {
			if (this.selectedCategory) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		// Filter projects by category
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter projects by title search
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- Projects grid -->
	<section class="pt-14 sm:pt-14 pb-14 sm:pb-14 bg-my3dp-white">
		<div class="triangle">
			<img src="@/assets/images/rectangle_page.png" alt="Logo Big" width="57" height="28">
		</div>
        <div class="w-[90%] max-w-[1024px] m-auto text-center text-my3dp-white">
		<!-- Projects grid title -->
		<div class="text-center text-my3dp-blue">
			<h2>
			<p
					class="font-roboto-light text-3xl sm:text-3xl mb-3 text-my3dp-blue"
			>
					{{ $t('message.home_concept_titre') }}
				</p>
			</h2>
            <p>
				{{ $t('message.home_concept_content01') }} 
				<span class="font-roboto-bold">{{ $t('message.home_concept_content02') }}</span>
                {{ $t('message.home_concept_content03') }} 
				<span class="font-roboto-bold">{{ $t('message.home_concept_content04') }}</span>
                {{ $t('message.home_concept_content05') }}
				<span class="font-roboto-bold">{{ $t('message.home_concept_content06') }}</span>                
				{{ $t('message.home_concept_content07') }} 
				<span class="font-roboto-bold">{{ $t('message.home_concept_content08') }}</span>                
				{{ $t('message.home_concept_content09') }} 
				<span class="font-roboto-bold">{{ $t('message.home_concept_content10') }}</span>                
				{{ $t('message.home_concept_content11') }} 


				<!-- Notre <span class="font-roboto-bold">outil d’architecture 3D gratuit</span> peut être utilisé pour créer des plans pour la cuisine, la salle de bains, le séjour, la chambre, le bureau, le rangement ou plus généralement l'immobilier. -->
<!-- MY3DPLANNER peut aussi répondre à tout autre besoin ou métier. -->
<!-- Vous êtes un professionel dans le secteur du <span class="font-roboto-bold">meuble</span>, de l'<span class="font-roboto-bold">architecture</span> ou de l'<span class="font-roboto-bold">immobilier</span>, ou vous avez tout simplement besoin de dessiner des <span class="font-roboto-bold">plans d'intérieur et/ou d'extérieur 2D-3D</span>, MY3DPLANNER est la solution qu'il vous faut! -->

            </p>
            <p>
				{{ $t('message.home_concept_content20') }} 
				<span class="font-roboto-bold">{{ $t('message.home_concept_content21') }}</span>
				{{ $t('message.home_concept_content22') }} 
				<span class="font-roboto-bold">{{ $t('message.home_concept_content23') }}</span>
				{{ $t('message.home_concept_content24') }} 
                <!-- Notre logiciel de plan 2D/3D est un <span class="font-roboto-bold">formidable outil d’aide à la vente</span> qui rend réel votre projet et vous aide à convaincre vos clients. Vous pouvez grâce à notre <span class="font-roboto-bold">outil de dessin de plans</span> montrer à vos clients comment sera son intérieur ! La visualisation des pièces en 3D ainsi transformées et meublées les aide à se projeter et accélère la concrétisation de vos ventes. -->

            </p>
			<p>
				{{ $t('message.home_concept_content30') }} 
				<span class="font-roboto-bold">{{ $t('message.home_concept_content31') }}</span>
				{{ $t('message.home_concept_content32') }} 
				<br />
				{{ $t('message.home_concept_content33') }}
			</p>
			<button 
				class="inline-block bg-my3dp-blue hover:bg-my3dp-darkBlue text-white font-bold py-2 md:py-3 px-3 md:px-4 mt-10 rounded-full text-sm md:text-base"
				@click="$router.replace( $i18n.locale == 'en' ? '/en/solutions' : '/solutions' )"
			>
			
				
				{{ $t('message.home_concept_contentBt') }}
				<i class="inline-block ml-1 w-8 h-8" data-feather="arrow-right-circle"></i>
			</button>
		</div>
		</div>

	</section>
</template>

<style scoped>
.triangle {
	position: absolute;
    margin: auto;
    margin-left: calc(50% - 29px);
    margin-top: -3.5rem;
}
</style>
