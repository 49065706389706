<script>
// import LangSwitcher from '../LangSwitcher';
export default {
	components: {
		// LangSwitcher,
	},
	props: ['showModal', 'isOpen'],
		// created() {
		// this.theme = localStorage.getItem('theme') || 'light';
	methods: {
		goToApp () {
			// window.location.href = 'https://app.my3dplanner.com/';
			if ( this.$i18n.locale === 'en' ) {
				window.location.href = 'https://app.my3dplanner.com/?lang=en';
			}else{
				window.location.href = 'https://app.my3dplanner.com/';
			}
		}
	}
	
};
</script>

<template>
	
	
	
	
	
	<!-- Header links -->
	<div
		:class="isOpen ? 'block' : 'hidden'"
		class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center place-items-end shadow-lg sm:shadow-none font-roboto-medium"
	>
		
	<!-- :to="$i18n.locale == 'en' ? './en/solutions' : './solutions'" -->
	<!-- :to="{ path: $i18n.locale == 'en' ? '/en/solutions' : '/solutions'}" -->
		<router-link
			:to="{ path: $i18n.locale == 'en' ? '/en/solutions' : '/solutions'}"
			action="replace"
			class="font-roboto-medium block text-left text-lg font-medium text-my3dp-blue hover:text-my3dp-yellow sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light"
			aria-label="Découvrez nos solutions"
			>{{ $t('message.menu_concept') }}</router-link
		>
		<!-- :to="$i18n.locale == 'en' ? 'en/prices' : '/tarifs'" -->
		<router-link
			:to="{ path: $i18n.locale == 'en' ? '/en/prices' : '/tarifs'}"
			action="replace"
			class="font-roboto-medium block text-left text-lg font-medium text-my3dp-blue hover:text-my3dp-yellow sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light"
			aria-label="Découvrez nos tarifs"
			>{{ $t('message.menu_tarif') }}</router-link
		>
		<router-link
			:to="{ path: $i18n.locale == 'en' ? '/en/contact' : '/contact'}"
			action="replace"
			class="font-roboto-medium block text-left text-lg font-medium text-my3dp-blue hover:text-my3dp-yellow sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light"
			aria-label="Contactez-nous pour toutes informations"
			>{{ $t('message.menu_contact') }}</router-link
		>
		
		<div
			class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light"
		>
		<!-- @click="$router.push('my3dplanner')" -->
			<button
				class="text-md  sm:hidden block text-left font-roboto-light bg-my3dp-yellow hover:bg-my3dp-blue text-my3dp-black hover:text-my3dp-white shadow-sm rounded-lg px-5 py-2.5 duration-300"
				@click="goToApp"
				aria-label="Essayez gratuitement l'application MY3DPLANNER"
			>
				<!-- Essayez-moi -->
				{{ $t('message.menu_essayez') }}
			</button>
		</div>
	</div>
	
</template>

<style lang="scss" scoped></style>
