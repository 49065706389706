export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://www.my3dplanner.com/',
	},
	{
		id: 5,
		name: 'YouTube',
		icon: 'youtube',
		url: 'https://www.youtube.com/c/my3dplanner',
	},
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/my3dplanner',
	},
	
	
	
	
	
	
];
