<script>
// import feather from 'feather-icons';
// import Flicking from "@egjs/vue3-flicking";
// import Embed from 'v-video-embed'
// import LazyYoutube from 'vue-lazytube'

export default {
	components: { 
		
	},
	data: () => {
		return {
		};
	},
	computed: {
	},
	methods: {


	},
	mounted() {
	},
};
</script>

<template>
	<section class="pt-2 sm:pt-2 pb-14 sm:pb-14 bg-my3dp-white">
        <div class="w-[90%] max-w-[768px] m-auto text-center text-my3dp-white bkg-my3dp-white">
			
		<div class="text-center text-my3dp-blue w-[90%] max-w-[1024px] m-auto bkg-my3dp-white" >
			<h2>
			<p
				class="font-roboto-light text-3xl sm:text-3xl mb-3 text-my3dp-WHITE"
            >
				{{ $t('message.home_video_titre') }}
			</p>
			</h2>
			
			
			<div class="bkg-my3dp-white">
				<iframe class="mx-auto youtube-video" src="https://www.youtube.com/embed/RovR_Fk-D5c?si=VvHk8Djuuuf49V-p&rel=0" title="My3dplanner video presentaiton" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
				
				
				
			</div>
		</div>
		</div>
	</section>
</template>

<style scoped>
.triangle {
	position: absolute;
    margin: auto;
    margin-left: calc(50% - 29px);
    margin-top: -3.5rem;
}
.youtube-video {
  aspect-ratio: 16 / 9;
  width: 100%;
}
</style>
