import { createApp } from 'vue';
// import VueI18n from 'vue-i18n'
import i18n from "@/i18n"
import App from './App.vue';
import router from './router';
import './assets/css/app.css';
import './assets/css/flicking.min.css';
import BackToTop from 'vue-backtotop';
import gsap from "gsap";
import './registerServiceWorker'
import Embed from 'v-video-embed'
// import ScrollTrigger from "gsap/ScrollTrigger";

// import Flicking from "../dist/flicking.esm";
// import "@egjs/vue3-flicking/dist/flicking.css";
const feather = require('feather-icons');
feather.replace();

// export const i18n = new VueI18n({
// locale: 'en',
// fallbackLocale: 'en'
// });

createApp(App)
	.use(router)
	.use(BackToTop)
	.use(i18n)
	.use(gsap)
	.use(Embed)
	// .use(ScrollTrigger)
	// .use(VueI18n)
	.mount('#app');


const appTheme = localStorage.getItem('theme');
// const appLang = localStorage.getItem('lang');

// Check what is the active theme and change theme when user clicks on the theme button in header.
if (
	appTheme === 'dark' &&
	document.querySelector('body').classList.contains('app-theme')
) {
	document.querySelector('body').classList.add('bg-primary-dark');
} else {
	document.querySelector('body').classList.add('bg-secondary-light');
}
// console.log("appLang", appLang)
// if ( 
// appLang === 'en'
// ) {
// this.$i18n.locale = 'en'
// }
