<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {
		goToApp () {
			if ( this.$i18n.locale === 'en' ) {
				window.location.href = 'https://app.my3dplanner.com/?lang=en';
			}else{
				window.location.href = 'https://app.my3dplanner.com/';
			}
		}
	},
};
</script>

<template>
	<!-- Banner -->
	<section
		class="w-full min-height-[500px] h-auto flex flex-col sm:justify-between items-center sm:flex-row mt-0 mb-[0px] bg-my3dp-darkWhite"
		style="margin-bottom:0px !important;"
	>
		<div class="customCoverSize m-auto">

				<div>

					<img fetchpriority="high" srcset="@/assets/images/HOME-MY3DPLANNERx320.webp 320w, @/assets/images/HOME-MY3DPLANNERx480.webp 480w, @/assets/images/HOME-MY3DPLANNERx640.webp 640w, @/assets/images/HOME-MY3DPLANNERx800.webp 800w" src="@/assets/images/HOME-MY3DPLANNER.webp" class="object-cover w-full h-full " alt="Home MY3DPLANNER" />

					<div class="avatar hidden sm:block">
						<img src="@/assets/images/perso_my3dplanner.webp" class="" width="968" height="793" alt="avatar my3dplanner"/>
					</div>

				</div>

				<div class="absolute h-[220px] w-[280px] sm:w-[40%] ml-[20%] sm:ml-[48%] lg:ml-[530px] max-w-[400px] z-20 mt-[-50%] md:mt-[-400px] text-left" >
					<div class="absolute">
						<img src="@/assets/images/logo_my3dplanner_n.webp" class="mb-4" width="172" height="116" alt="logo black" />
						<h1>
							<div class="ombre font-roboto-light px-4 py-4">{{ $t('message.banner_titre00') }}</div><br/>
							<div class="ombre font-roboto-light px-4 py-4 mt-[-20px] mb-[-20px]">{{ $t('message.banner_titre01') }}</div><br/>
							<div class="ombre font-roboto-light px-4 py-4">{{ $t('message.banner_titre02') }}</div>
						</h1>
						<!-- <br/> -->
						<!-- @click="$router.replace( $i18n.locale == 'en' ? '/en/solutions' : '/solutions' )" -->
						<!-- @click="$router.push('my3dplanner')" -->
						<button 
						@click="goToApp"
						class="inline-block bg-my3dp-blue hover:bg-my3dp-darkBlue text-white font-bold py-2 md:py-3 px-3 md:px-4 mt-10 rounded-full text-sm md:text-base"
						>
							{{ $t('message.banner_titreBt') }}
							<i class="inline-block ml-1 w-8 h-8" data-feather="arrow-right-circle"></i>
						</button>
					</div>
				</div>
		</div>
	</section>
</template>

<style scoped>
.avatar {
	position: absolute;
	width: 450px;
	margin-top: -368px;
	margin-left: 10px;
	/* padding-left: 5rem; */
}
.customCoverSize {
	/* position: absolute; */
	max-width: 1024px;
	min-height: 320px;
    /* margin: auto; */
}
.ombre {
	background-color: #000;
    /* padding: 8px 8px; */
    font-size: 1.7em;
    line-height: 1.4em;
    box-shadow: -10px 10px 0 4px rgba(251,182,49,1);
	color: #fff;
	text-align: left;
	display: inline-block;
}

@media screen and (max-width: 1024px) {
	.avatar {
		width: 400px;
		margin-top: -327px;
	}
	.ombre {
		font-size: 1.4em;
		line-height: 1.4em;
	}
}

@media screen and (max-width: 768px) {
	.avatar {
		width: 350px;
		margin-top: -287px;
	}
	.ombre {
		font-size: 1.2em;
		line-height: 1.2em;
	}
}

@media screen and (max-width: 640px) {
	.avatar {
		width: 250px;
		margin-top: -205px;
	}
	.ombre {
		font-size: 1.0em;
		line-height: 1.0em;
	}
}
</style>
