import { createI18n } from 'vue-i18n'
const messages = {
    en: {
        message: {
            hello: 'Hello',
            close: 'CLOSE',
            banner_titre00: `ONLINE`,
            banner_titre01: `2D/3D DRAWING`,
            banner_titre02: `SOFTWARE`,
            banner_titreBt: `Try for free`,
            menu_presentation: 'PRESENTATION',
            menu_concept: 'SOLUTIONS',
            menu_tarif: 'PRICES',
            menu_contact: 'CONTACT',
            menu_essayez: 'TRY ME',
            home_presentation: 'PRESENTATION',
            home_presentation_txt01: 'Simple and fast,',
            home_presentation_txt02: ' designed for everyone, MY3DPLANNER is a ',
            home_presentation_txt03: 'free 3D interior design software',
            home_presentation_txt04: 'that allows you to create your ',
            home_presentation_txt05: 'room, apartment, or house plans',
            home_presentation_txt06: 'in just a few clicks and customize them with a library of furniture and finishes.',
            home_presentation_txt07: 'Our easy-to-use 3D drawing software',
            home_presentation_txt08: 'can be used without downloading or installing anything. You can ',
            home_presentation_txt09: `design, furnish, personalize, and visualize`,
            home_presentation_txt10: `your architectural and decoration projects directly in your browser. Our 3D drawing tool is easy to use on a computer or tablet.`,
            home_presentation_txt11: `NEW!`,
            home_presentation_txt12: `Get photorealistic renderings with `,
            home_presentation_txt13: `Artificial Intelligence.`,
            home_presentation_chambre: `The Bedroom`,
            home_presentation_salon: `The Living Room`,
            home_presentation_sdb: `The Bathroom`,
            home_presentation_cuisine: `The Kitchen`,
            home_presentation_autre: `And More...`,
            home_carroussel_titre: `DISCOVER IN PICTURES`,
            home_carroussel_legende: `Images generated by Generative Artificial Intelligence (Generative AI)`,
            home_carroussel_alt1: `Rendered using Generative Artificial Intelligence (Generative AI)`,

            home_concept_titre: `CONCEPT`,
            home_concept_content01: `Our`,
            home_concept_content02: `free 3D architecture tool`,
            home_concept_content03: `can be used to create plans for the kitchen, bathroom, living room, bedroom, office, storage, or more generally, real estate. MY3DPLANNER can also suit any other need or profession. Whether you're a professional in the`,
            home_concept_content04: `furniture industry,`,
            home_concept_content05: ``,
            home_concept_content06: `architecture,`,
            home_concept_content07: ` or`,
            home_concept_content08: `real estate,`,
            home_concept_content09: ` or you simply need to design`,
            home_concept_content10: `2D-3D interior and/or exterior plans,`,
            home_concept_content11: `MY3DPLANNER is the solution for you!`,
            home_concept_content20: `Our 2D/3D design software is a`,
            home_concept_content21: `powerful sales tool`,
            home_concept_content22: ``,
            home_concept_content23: ``,
            home_concept_content24: `to show your clients how their interior will look. The visualization of transformed and furnished 3D rooms helps them envision and accelerates the realization of your sales.`,
            home_concept_content30: `For kitchen designers, bathroom creators, furniture manufacturers, real estate agents,`,
            home_concept_content31: `our interior design and decoration software`,
            home_concept_content32: `allows you to easily create 3D plans and quickly present multiple projects to your clients before refining their preferred layout.`,
            // home_concept_content32: `allows you to easily create 3D plans and quickly present multiple projects to your clients before refining their preferred layout.`,
            home_concept_content33: `MY3DPLANNER also lets you design all your spaces: terrace, garden, building, office, store, apartment, house, and more...`,
            home_concept_contentBt: `Learn more`,

            home_perso_titre: `SERVICE FOR BUSINESSES`,
            home_perso_content01: `With a`,
            home_perso_content02: `simple and intuitive interface,`,
            home_perso_content03: `visualize your projects in 3 dimensions and modelize them with MY3DPLANNER.`,
            home_perso_content04: `Featuring a 3D modeling system, MY3DPLANNER is a tool designed for professionals (architects, real estate agents, furniture manufacturers...)`,
            home_perso_content05: `It combines`,
            home_perso_content06: `simplicity of design`,
            home_perso_content07: `and visuals calculated by`,
            home_perso_content08: `Artificial Intelligence`,
            home_perso_content09: `in an all-in-one solution.`,
            home_perso_content10: `An all-in-one`,
            home_perso_content11: `sales and marketing tool;`,
            home_perso_content12: `MY3DPLANNER allows you to increase your sales and better interact with your clients. MY3DPLANNER can be implemented as a white-label solution on your website, and the generated projects can be seamlessly integrated into your workflow.`,
            home_perso_content13: `A tailor-made offer can be made based on the number of users or projects. Our offers include updates and support.`,
            home_perso_contentBt: `Contact us`,
            solution_intro_titre: `SOLUTIONS`,
            solution_intro_sstitre: `MY3DPLANNER is designed for both individuals and professionals. It provides the ability to easily and quickly draw your interior online, then customize it, visualize it, and share it...`,
            solution_intro_etape01Titre: `Draw the Plans`,
            solution_intro_etape01Content: `In a few clicks, draw and stretch the walls to create your plan. Add doors, windows, and other structural elements.`,
            solution_intro_etape02Titre: `Furnish`,
            solution_intro_etape02Content: `Catalogs of equipment, furniture, and decorative elements are available to allow the user to furnish their interior.`,
            solution_intro_etape03Titre: `Visualize`,
            solution_intro_etape03Content: `With easy handling, move the camera and change floor colors and wall finishes. Immerse yourself in your interior with high-quality rendering.`,
            solution_intro_etape04Titre: `Finalize the project`,
            solution_intro_etape04Content: `At any time, access the summary of your project. Once satisfied, save and share your project. It can be modified and shared again later.`,
            solution_intro_etape05Titre: `Export`,
            solution_intro_etape05Content: `Choose between a low-resolution rendering for quick visualization or opt for the AI photorealistic rendering for exceptional quality. Export your project in 3D format. Easy, comprehensive, and tailored to your needs.`,
            solution_marque_titre: `WHITE-LABEL APPLICATION`,
            solution_marque_content01: `MY3DPLANNER is a user-friendly 3D drawing software that easily integrates into any website. It is a`,
            solution_marque_content02: `fully customizable tool`,
            solution_marque_content03: `to match your brand. The visual identity, user interface, and features of our 2D/3D design software are entirely`,
            solution_marque_content04: `modifiable`,
            solution_marque_content05: `to fit your needs.`,
            solution_marque_content06: `The 3D library of furniture and finishes is also customizable to match your catalogs and requirements. A true sales and marketing tool; MY3DPLANNER allows you to offer`,
            solution_marque_content07: `customized online 2D and 3D plans`,
            solution_marque_content08: `to meet the unique needs of your business.`,
            solution_marque_content09: `Use our`,
            solution_marque_content10: `artificial intelligence`,
            solution_marque_content11: `or personalized design suggestions.`,
            solution_marque_content12: `Export your project in 3D format`,
            solution_marque_content13: `for seamless integration into your workflow.`,
            solution_marque_contentBt: `Contact us`,
            solution_usage_titre: `USE CASES`,
            solution_usage_content01_1: `The Bedroom`,
            solution_usage_content01_2: `MY3DPLANNER is a`,
            solution_usage_content01_3: `free 3D bedroom design software.`,
            solution_usage_content01_4: `Thanks to our wide selection of bedroom furniture and finishes, you can easily create the bedroom of your dreams. Design the layout of your new bedroom and furnish it in 3D!`,
            solution_usage_content01_5: `You can also use MY3DPLANNER to arrange your walk-in closet or wardrobe and visualize it in 3D for free.`,
            solution_usage_content01_6: `For wardrobe and bedroom furniture creators, don't hesitate to contact us to have your furniture, closets, and wardrobes integrated into our`,
            solution_usage_content01_7: `3D bedroom design tool.`,
            solution_usage_content02_1: `The Kitchen`,
            solution_usage_content02_2: `MY3DPLANNER is a`,
            solution_usage_content02_3: `free 3D kitchen design software.`,
            solution_usage_content02_4: `Thanks to our wide selection of kitchens and kitchen furniture, you can easily create the kitchen of your dreams. Design the layout of your new kitchen and furnish it in 3D!`,
            solution_usage_content02_5: `You can also use MY3DPLANNER to plan an open-concept kitchen with a dining area or a kitchen island and visualize them in 3D for free.`,
            solution_usage_content02_6: `For kitchen designers and creators of kitchen furniture, don't hesitate to contact us to have your furniture and decorative items integrated into our`,
            solution_usage_content02_7: `3D kitchen design tool.`,
            solution_usage_content03_1: `The Living Room`,
            solution_usage_content03_2: `MY3DPLANNER is a`,
            solution_usage_content03_3: `free 3D living room design software.`,
            solution_usage_content03_4: `Thanks to our wide selection of living room and dining room furniture, you can easily create the interior of your dreams. Design the layout of your new living room and furnish it in 3D!`,
            solution_usage_content03_5: `You can also use MY3DPLANNER to plan your dining room or any other room in the house and visualize them in 3D for free.`,
            solution_usage_content03_6: `For designers of living room, lounge, or dining room furniture, don't hesitate to contact us to have your furniture and decorative items integrated into our`,
            solution_usage_content03_7: `3D living room, lounge, and dining room design tool.`,
            solution_usage_content04_1: `The Bathroom`,
            solution_usage_content04_2: `MY3DPLANNER is a`,
            solution_usage_content04_3: `free 3D bathroom design software.`,
            solution_usage_content04_4: `Thanks to our wide selection of bathroom furniture and sanitary ware, you can easily create the bathroom of your dreams. Design the layout of your new bathroom and furnish it in 3D!`,
            solution_usage_content04_5: `You can also use MY3DPLANNER to design an open layout with the bedroom or a walk-in shower and visualize them in 3D for free.`,
            solution_usage_content04_6: `For creators of bathroom furniture or sanitary ware, don't hesitate to contact us to have your furniture and decorative items integrated into our`,
            solution_usage_content04_7: `3D bathroom design tool.`,
            solution_usage_content05_1: `Other...`,
            solution_usage_content05_2: `MY3DPLANNER is a`,
            solution_usage_content05_3: `free 3D home, apartment, and office space drawing software.`,
            solution_usage_content05_4: `Thanks to our wide selection of partitions, furniture, and finishes, you can easily create the layout project of your dreams.`,
            solution_usage_content05_5: `Design the layout of your new residence, terrace, or garden and furnish it in 3D!`,
            solution_usage_content05_6: `You can also use MY3DPLANNER to plan your store or boutique and visualize it in 3D for free.`,
            solution_tarif_titre: `PRICING`,
            solution_tarif_btMensuel: `MONTHLY`,
            solution_tarif_btAnnuel: `ANNUAL`,
            solution_tarif_zone1_1: `Free`,
            solution_tarif_zone1_2a: `€0`,
            solution_tarif_zone1_2b: `€0`,
            solution_tarif_zone1_3a: `/month`,
            solution_tarif_zone1_3b: `/year`,
            solution_tarif_zone1_4: `2 projects`,
            solution_tarif_zone1_5: `Unlimited standard renderings`,
            solution_tarif_zone1_6: `3D furniture library`,
            solution_tarif_zone1_7: `MY3DPLANNER logo overlay`,
            solution_tarif_zone1_bt: `Log In`,
            solution_tarif_zone2_1: `Start`,
            solution_tarif_zone2_2a: `€3.90`,
            solution_tarif_zone2_2b: `€39`,
            solution_tarif_zone2_2c: `2 months free for annual commitment`,
            solution_tarif_zone2_4: `4 projects`,
            solution_tarif_zone2_5: `Unlimited standard renderings`,
            solution_tarif_zone2_6: `1000 credits for AI renderings *`,
            solution_tarif_zone2_7: `Priority support`,
            solution_tarif_zone2_8: `3D furniture library`,
            solution_tarif_zone2_9: `MY3DPLANNER logo overlay`,
            solution_tarif_zone3_1: `Regular`,
            solution_tarif_zone3_2a: `€8.90`,
            solution_tarif_zone3_2b: `€89`,
            solution_tarif_zone3_4: `12 projects`,
            solution_tarif_zone3_5: `Unlimited standard renderings`,
            solution_tarif_zone3_6: `2000 credits for AI renderings *`,
            solution_tarif_zone4_1: `Pro`,
            solution_tarif_zone4_2a: `€17.90`,
            solution_tarif_zone4_2b: `€179`,
            solution_tarif_zone4_4: `50 projects`,
            solution_tarif_zone4_5: `Unlimited standard renderings`,
            solution_tarif_zone4_6: `5000 credits for AI renderings *`,
            solution_tarif_zone5_1: `Enterprise`,
            solution_tarif_zone5_2: `Contact Us`,
            solution_tarif_zone5_3: `White-label installation`,
            solution_tarif_zone5_3_1: `Unlimited number of users`,
            solution_tarif_zone5_4: `Custom developments`,
            solution_tarif_zone5_5: `Integration of your 3D models and finishes`,
            solution_tarif_zone5_6: `Modeling of your 3D models`,
            solution_tarif_zone5_bt: `Contact Us`,
            solution_tarif_ast01: `* CREDITS`,
            solution_tarif_ast02: `10 credits for a 768x512px rendering.`,
            solution_tarif_ast03: `30 credits for a 1024x768px rendering.`,
            solution_tarif_ast04: `40 credits for a 2048x1536px rendering.`,
            solution_tarif_ast05: ``,
            solution_tarif_ast05old: `** "Unlimited" usage is subject to reasonable conditions to ensure a fair experience for all our users.`,
            contact_titre: "Contact Us",
            contact_votreMail: "Your email:",
            contact_votreNom: "Your name:",
            contact_votreMessage: "Your message:",
            contact_btEnvoyer: "Send",
            contact_detail_titre: "Coordinates",
            contact_detail_nom: "CADELOG",
            contact_detail_ad1: "118 rue de Vaugirard",
            contact_detail_ad2: "75006 PARIS",
            contact_detail_mail: "hello@my3dplanner.com",
            contact_detail_tel: "+33 1 85 08 95 05",
            contact_sendLoad:`Please wait...`,
            contact_sendSuccess:`Your email has been sent. We will process your request as quickly as possible.`,
            contact_sendError:`A problem has occurred. Please try again or use another method to contact us.`,
            footer_contact: "CONTACT",
            footer_mention: "LEGAL NOTICE",
            mention_titre: "Legal Notice and Personal Data",
            mention_sstitre1: "Website Publishing",
            mention_content1: `My3dplanner.com is a website published by the 
            company cadeLOG.
            cadeLOG is a SAS company with a capital of 6000 euros, with its 
            registered office in Paris (75006) at 118 rue de Vaugirard, registered 
            with the Paris Trade and Companies Register under number 488 903 246 
            (VAT identification number FR7488903246).
            Publication director : Sébastien AMELIN`,
            mention_sstitre2: "Intellectual Property",
            mention_content2: `You can visit this website on the Internet without 
            having to disclose your identity and provide personal information 
            about yourself.`,
            mention_sstitre3: `Modification of the Website`,
            mention_content3: `The editorial team reserves the right to modify or correct the content of this website and its legal notices at any time and without prior notice.`,
            mention_sstitre4: `Hosting`,
            mention_content4_1: `GANDI SAS,`,
            mention_content4_2: `Simplified Joint Stock Company with a capital of 2,300,000€, having its registered office at 63-65 boulevard Masséna`,
            mention_content4_3: `Paris (75013)`,
            mention_content4_4: `FRANCE`,
            mention_content4_5: `Siren 423 093 459 RCS PARIS`,
            mention_content4_6: `VAT number FR81423093459`,
            mention_content4_7: `Phone +33 (0) 1 70.37.76.61`,
            mention_content4_8: `Fax +33. (0) 1 43 73 18 51`,
            mention_content4_9: `GOOGLE CLOUD PLATFORM - Firebase Hosting,`,
            mention_content4_10: `whose registered office is located at Google LLC,`,
            mention_content4_11: `1600 Amphitheatre Parkway,`,
            mention_content4_12: `Mountain View,`,
            mention_content4_13: `California 94043 USA`,
            mention_sstitre5: `Cookie Notices`,
            mention_content5: `A cookie is a text file that can be saved, subject to your choices, in a dedicated space on the hard drive of your terminal (computer, smartphone, tablet, etc.) when you visit an online service using your web browser.
            This cookie is sent by the website's server to your browser. Each cookie is assigned a unique anonymous identifier. The cookie file allows its issuer to identify the terminal in which it is stored for the duration of the relevant cookie's validity.
            When you visit the my3dplanner.com website, it is possible that cookies are installed for the proper functioning of the site or, subject to your choice, cookies related to advertising on the site.`,
            mention_sstitre6: `Types of Cookies`,
            mention_sstitre7: `Google AdSense Advertising Cookies`,
            mention_content7: `Third-party advertising services such as Adsense (and its partners) control cookies from their advertising spaces. To learn more about Google's targeted ads, click the following link: Privacy Policy
            This website is not responsible for the management and lifespan of these third-party cookies and invites you to read the policy of each of these providers.
            Their purpose is to send you personalized ads tailored to your expectations. No personal data such as your name, address, or email, etc., will be transmitted to these third-party partners, whose involvement on the website is limited to the placement of cookies through the advertising content they manage.
            This website has no control over these cookies.`,
            mention_sstitre8: `Google Analytics Statistics Cookies`,
            mention_content8: `These cookies allow for the establishment of visitation statistics on the my3dplanner.com websites and the detection of navigation problems in order to monitor and improve the quality of our services.
            Cookie Choices in Your Browser
            You can configure your browser at any time to change the way cookies are stored on your terminal. You can express your choices by configuring your browser to reject certain cookies.
            If you refuse our cookies and those of our partners, your visits to my3dplanner.com websites will no longer be counted in Google Analytics, and it is possible that some features may no longer function correctly or certain services may be less accessible in the event of cookie refusal.
            For more information about cookies, we invite you to consult the CNIL page on this topic.`,
            mention_sstitre9: `Privacy Policy`,
            mention_sstitre10: `Contact Forms`,
            mention_content10: `Information sent via a contact form is retained for an indefinite period for questions related to the visitor's request. However, it will never be used for other purposes (no commercial or other use).`,
            mention_sstitre11: `Application Registration Forms`,
            mention_content11: `Your email will be mandatory for creating a user account. Access to personal data is strictly limited to cadeLOG staff authorized to process them due to their duties and to our possible computer subcontractors. These subcontractors are subject to a confidentiality obligation and can only use your data in accordance with our contractual provisions and applicable law. We undertake not to sell, rent, transfer, or grant access to your data to third parties without your prior consent, unless required to do so for a legitimate reason (legal obligation, combating fraud or abuse, exercise of defense rights, etc.).`,
            mention_sstitre12: `Information Protection`,
            mention_content12: `We implement a variety of security measures to ensure the security of your personal information. We also protect your information offline. The computers and servers used to store personally identifiable information are kept in a secure environment. Feel free to contact us to learn more.`,
            cgv_link: "T&Cs",
            cgv_titre: "Terms and Conditions of Sale",
            cgv_sstitre1: "Purpose of the Contract",
            cgv_content11: "These Terms and Conditions govern the terms of use and sale of the services offered by the websites my3dplanner.com, 3dplanner.app, and their subdomains.",
            cgv_content12: "By using our services, you agree to be bound by these terms.",
            cgv_sstitre2: "Description of the Services",
            cgv_content21: "Our website offers a virtual interior design and planning service.",
            cgv_content22: "You can create projects, customize spaces, add furniture, and visualize your design in 3D.",
            cgv_sstitre3: "Use of the Service",
            cgv_content31: "By using our service, you agree to comply with our terms of use.",
            cgv_content32: "You may not use our services for illegal, fraudulent, or rights-infringing purposes.",
            cgv_content33: "You are responsible for the content you create and share on our website.",
            cgv_sstitre4: "Rates and Payment",
            cgv_content41: "The rates for our services are displayed on our website and may be changed at any time without notice.",
            cgv_content42: "Payments are made online using the payment methods accepted on our platform.",
            cgv_content43: "Payments are non-refundable, except in the case of a major technical issue attributable to our website.",
            cgv_sstitre5: "Intellectual Property",
            cgv_content51: "You retain the intellectual property rights to the content you create and share on our website.",
            cgv_content52: "However, by using our services, you grant us a non-exclusive, worldwide, and royalty-free license to use, reproduce, modify, and distribute that content for the purpose of providing our services.",
            cgv_sstitre6: "Privacy",
            cgv_content61: "We respect your privacy and protect your personal data in accordance with our privacy policy.",
            cgv_content62: "We will not sell or share your personal information with third parties without your consent.",
            cgv_sstitre7: "Limitation of Liability",
            cgv_content71: "We strive to provide a high-quality service, but we cannot guarantee the accuracy, reliability, or completeness of our services.",
            cgv_content72: "We disclaim any liability for direct, indirect, incidental, special, or consequential damages arising from the use of our services.",
            cgv_sstitre8: "Modification of the Terms and Conditions",
            cgv_content81: "We reserve the right to modify these Terms and Conditions at any time. Changes will take effect upon their publication on our website. It is your responsibility to regularly check the updated Terms and Conditions.",
            cgv_sstitre9: "Applicable Law and Competent Jurisdiction",
            cgv_content91: "These Terms and Conditions are governed by French law. In case of a dispute, the competent courts will be those within the jurisdiction of Paris.",
            cgv_content10: "Feel free to contact us to learn more.",
            home_tileFaq: "FREQUENTLY ASKED QUESTIONS (FAQ)",
            home_video_titre: `DISCOVER IN VIDEO`,
            solution_tarif_more: `For more credits, contact us.`,
        }
    },
    fr: {
        message: {
            hello: 'Bonjour',
            close: 'FERMER',
            banner_titre00: `LOGICIEL`,
            banner_titre01: `D'AMÉNAGEMENT 2D/3D`,
            banner_titre02: `EN LIGNE`,
            banner_titreBt: `Éssayez gratuitement`,
            menu_presentation: 'PRÉSENTATION',
            menu_concept: 'SOLUTIONS',
            menu_tarif: 'TARIFS',
            menu_contact: 'CONTACT',
            menu_essayez: 'ESSAYEZ MOI',
            home_presentation: "PRÉSENTATION",
            home_presentation_txt01: `Simple et rapide,`,
            home_presentation_txt02: ` conçu pour tous, MY3DPLANNER est un `,
            home_presentation_txt03: `logiciel d’aménagement d’intérieur 3D gratuit`,
            home_presentation_txt04: `qui vous permet de créer en quelques clics vos `,
            home_presentation_txt05: `plans de pièces, d'appartement ou de maison`,
            home_presentation_txt06: `et de les personnaliser avec une bibliothèque de meubles et de finitions.`,
            home_presentation_txt07: `Notre logiciel de plan en 3D facile d'utilisation`,
            home_presentation_txt08: ` s’utilise sans téléchargement et sans installation, vous pouvez `,
            home_presentation_txt09: `concevoir, meubler, personnaliser et visualiser`,
            home_presentation_txt10: `vos projets d'architecture ou de décoration directement dans votre navigateur. Notre outil d’aménagement 3D s’utilise très facilement sur ordinateur ou tablette.`,
            home_presentation_txt11: `NOUVEAU !`,
            home_presentation_txt12: ` Obtenez des rendus photoréalistes grâce à `,
            home_presentation_txt13: `l'Intelligence Artificielle`,
            home_presentation_chambre: `La chambre`,
            home_presentation_salon: `Le salon`,
            home_presentation_sdb: `La salle de bains`,
            home_presentation_cuisine: `La cuisine`,
            home_presentation_autre: `Et plus encore...`,
            home_carroussel_titre: `DÉCOUVRIR EN IMAGES`,
            home_carroussel_legende: `Images générées par intelligence artificielle générative (IA générative)`,
            home_carroussel_alt1: `rendu avec l'intelligence artificielle générative (IA générative)`,
            // home_carroussel_alt2: `rendu d'un hall d'accueil avec l'intelligence artificielle générative (IA générative)`,
            // home_carroussel_alt3: `rendu d'un séjour avec l'intelligence artificielle générative (IA générative)`,
            // home_carroussel_alt4: `rendu d'un dressing avec l'intelligence artificielle générative (IA générative)`,
            // home_carroussel_alt5: `rendu d'un bureau avec l'intelligence artificielle générative (IA générative)`,
            // home_carroussel_alt6: `rendu d'un rangement avec l'intelligence artificielle générative (IA générative)`,
            // home_carroussel_alt7: `rendu d'une salle de bains avec l'intelligence artificielle générative (IA générative)`,
            // home_carroussel_alt8: `rendu d'une salle de bains avec l'intelligence artificielle générative (IA générative)`,
            home_concept_titre: `CONCEPT`,
            home_concept_content01: `Notre`,
            home_concept_content02: `outil d’architecture 3D gratuit`,
            home_concept_content03: `peut être utilisé pour créer des plans pour la cuisine, la salle de bains, le séjour, la chambre, le bureau, le rangement ou plus généralement l'immobilier.
            MY3DPLANNER peut aussi répondre à tout autre besoin ou métier.
            Vous êtes un professionel dans le secteur du`,
            home_concept_content04: `meuble,`,
            home_concept_content05: ` de `,
            home_concept_content06: `l'architecture`,
            home_concept_content07: ` ou de `,
            home_concept_content08: `l'immobilier,`,
            home_concept_content09: ` ou vous avez tout simplement besoin de dessiner des `,
            home_concept_content10: `plans d'intérieur et/ou d'extérieur 2D-3D,`,
            home_concept_content11: ` MY3DPLANNER est la solution qu'il vous faut!`,
            home_concept_content20: `Notre logiciel de plan 2D/3D est un`,
            home_concept_content21: `formidable outil d’aide à la vente`,
            home_concept_content22: `qui rend réel votre projet et vous aide à convaincre vos clients. Vous pouvez grâce à notre `,
            home_concept_content23: `outil de dessin de plans`,
            home_concept_content24: `montrer à vos clients comment sera son intérieur ! La visualisation des pièces en 3D ainsi transformées et meublées les aide à se projeter et accélère la concrétisation de vos ventes.`,
            home_concept_content30: `Pour vous cuisiniste, créateur de salle de bains, fabricants de meubles, agent immobilier,`,
            home_concept_content31: `notre logiciel d’aménagement et de décoration d’intérieur`,
            home_concept_content32: `vous permet de dessiner simplement des plans en 3D et de montrer très rapidement plusieurs projets à vos clients avant de pouvoir affiner le projet d’aménagement qu’il préfère.`,
            home_concept_content33: `MY3DPLANNER vous permet aussi d’agencer tous vos espaces : terrasse, jardin, immeuble, bureau, boutique, appartement, maison…`,
            home_concept_contentBt: `En savoir plus`,
            home_perso_titre: `SERVICE AUX ENTREPRISES`,
            home_perso_content01: `Interface`,
            home_perso_content02: `simple et intuitive,`,
            home_perso_content03: `imaginez vos projets en 3 dimensions et modélisez les avec MY3DPLANNER. `,
            home_perso_content04: `Doté d’un système de modélisation 3D, MY3DPLANNER est un outil à destination des professionels (architectes, agents immobilier, fabricants de meubles...).`,
            home_perso_content05: `Il allie`,
            home_perso_content06: `simplicité de la conception `,
            home_perso_content07: `et visuels calculés par une`,
            home_perso_content08: `Intelligence Artificielle`,
            home_perso_content09: `dans une solution tout-en-un.`,
            home_perso_content10: `Véritable`,
            home_perso_content11: `outil d'aide à la vente et au marketing;`,
            home_perso_content12: `MY3DPLANNER vous permet d'augmenter vos ventes et de mieux échanger avec vos clients. MY3DPLANNER peut être implémenté en marque blanche à votre site web, les projets générés peuvent être intégrés directement à votre flux de travail.`,
            home_perso_content13: `Une offre sur mesure pourra vous être faite en fonction du nombre d'utilisateurs ou de projets. Nos offres comprennent les mises à jour et l'assistance.`,
            home_perso_contentBt: `Nous contacter`,
            solution_intro_titre: `SOLUTIONS`,
            solution_intro_sstitre: `MY3DPLANNER est destiné aussi bien aux particuliers qu'aux professionnels. Il offre la possibilité de dessiner en ligne son intérieur facilement et rapidement pour ensuite le personnaliser, le visualiser, le partager...`,
            solution_intro_etape01Titre: `Dessinez les plans`,
            solution_intro_etape01Content: `En quelques clics dessinez, étirez les murs pour créer votre plan. Rajoutez les portes, fenêtres et autres éléments de structure.`,
            solution_intro_etape02Titre: `Meublez`,
            solution_intro_etape02Content: `Des catalogues d’équipements, de mobilier et d’éléments de décoration sont disponibles pour permettre à l’utilisateur d’équiper son intérieur`,
            solution_intro_etape03Titre: `Visualisez`,
            solution_intro_etape03Content: `Avec une prise en main facile, déplacez la caméra et changez les couleurs des sols, les finition des murs. Immergez-vous dans votre intérieur avec un rendu de haute qualité.`,
            solution_intro_etape04Titre: `Finalisez le projet`,
            solution_intro_etape04Content: `A tout moment accédez au récapitulatif de votre projet. Une fois satisfait, enregistrez votre projet et partagez le. Il pourra être ensuite modifié et partagé de nouveau.`,
            solution_intro_etape05Titre: `Exportez`,
            solution_intro_etape05Content: `Choisissez entre un rendu basse résolution pour une visualisation rapide, ou optez pour le rendu AI photoréaliste pour une qualité exceptionnelle. Exportez votre projet au format 3d. Facile, complet et adapté à vos besoins.`,
            solution_marque_titre: `APPLICATION EN MARQUE BLANCHE`,
            solution_marque_content01: `MY3DPLANNER est un logiciel d’aménagement 3D simple qui s’intègre facilement à tout site internet, c’est un `,
            solution_marque_content02: `outil complètement personnalisable`,
            solution_marque_content03: `à l’image de votre marque. La charte graphique, l’ergonomie, les fonctionnalités de notre logiciel de plan 2D/3D sont entièrement`,
            solution_marque_content04: `modifiables`,
            solution_marque_content05: `pour s’adapter à vos besoins.`,
            solution_marque_content06: `La bibliothèque 3D de meubles et de finitions sont également personnalisables selon vos catalogues et vos besoins. Véritable outil d'aide à la vente et au marketing; MY3DPLANNER vous permet de proposer des `,
            solution_marque_content07: `plans 2D et 3D en ligne personnalisés`,
            solution_marque_content08: `pour répondre aux besoins uniques de votre entreprise.`,
            solution_marque_content09: `Utilisez notre`,
            solution_marque_content10: `intelligence artificielle `,
            solution_marque_content11: `pour des suggestions de design sur mesure.`,
            solution_marque_content12: `Exportez au format 3d`,
            solution_marque_content13: `votre projet pour le réintégrer dans votre flux de travail`,
            solution_marque_contentBt: `Nous contacter`,
            solution_usage_titre: `CAS D'USAGE`,
            solution_usage_content01_1: `La chambre`,
            solution_usage_content01_2: `MY3DPLANNER est un`,
            solution_usage_content01_3: `logiciel d’aménagement de chambre 3D gratuit.`,
            solution_usage_content01_4: `Grâce à notre large sélection de meubles de chambre et de finitions vous pouvez créer très facilement la chambre de vos rêves. Dessinez le plan de votre nouvelle chambre et meublez-là en 3D !`,
            solution_usage_content01_5: `Vous pouvez également utiliser MY3DPLANNER pour aménager votre dressing ou votre placard et le visualiser gratuitement en 3D.`,
            solution_usage_content01_6: `Pour vous créateurs de dressing et de meubles de chambre, n’hésitez pas à nous contacter pour que vos meubles, placards et dressings soient intégrés à notre `,
            solution_usage_content01_7: `outil d’aménagement de chambre 3D.`,
            solution_usage_content02_1: `La cuisine`,
            solution_usage_content02_2: `MY3DPLANNER est un`,
            solution_usage_content02_3: `logiciel d’aménagement de cuisine 3D gratuit.`,
            solution_usage_content02_4: `Grâce à notre large sélection de cuisines et meubles de cuisine, vous pouvez créer très facilement la cuisine de vos rêves. Dessinez le plan de votre nouvelle cuisine et meublez-la en 3D !`,
            solution_usage_content02_5: `Vous pouvez également utiliser MY3DPLANNER pour aménager une cuisine américaine ouverte sur la salle à manger ou ilôt central de cuisine et les visualiser gratuitement en 3D.`,
            solution_usage_content02_6: `Pour vous cuisinistes et créateurs de meubles de cuisine, n’hésitez pas à nous contacter pour que vos meubles et objets de décoration, soient intégrés à notre `,
            solution_usage_content02_7: `outil d’aménagement de cuisine 3D.`,
            solution_usage_content03_1: `Le séjour`,
            solution_usage_content03_2: `MY3DPLANNER est un`,
            solution_usage_content03_3: `logiciel d’aménagement de séjour 3D gratuit.`,
            solution_usage_content03_4: `Grâce à notre large sélection de meubles de séjour et de salle à manger vous pouvez créer très facilement l’intérieur de vos rêves. Dessinez le plan de votre nouveau séjour et meublez-le en 3D !`,
            solution_usage_content03_5: `Vous pouvez également utiliser MY3DPLANNER pour aménager votre salle à manger ou toute autre pièce de la maison et les visualiser gratuitement en 3D.`,
            solution_usage_content03_6: `Pour vous créateurs de meubles de séjour, de salon ou de salle à manger, n’hésitez pas à nous contacter pour que vos meubles et objets de décoration, soient intégrés à notre `,
            solution_usage_content03_7: `outil d’aménagement de salon, séjour et salle à manger 3D.`,
            solution_usage_content04_1: `La salle de bains`,
            solution_usage_content04_2: `MY3DPLANNER est un`,
            solution_usage_content04_3: `logiciel d’aménagement de salle de bains 3D gratuit.`,
            solution_usage_content04_4: `Grâce à notre large sélection de meubles de salle de bains et de sanitaire vous pouvez créer très facilement la salle de bains de vos rêves. Dessinez le plan de votre nouvelle salle de bain et meublez-la en 3D !`,
            solution_usage_content04_5: `Vous pouvez également utiliser MY3DPLANNER pour concevoir une disposition ouverte sur la chambre ou une douche à l'italienne et les visualiser gratuitement en 3D.`,
            solution_usage_content04_6: `Pour vous créateurs de meubles de salle de bain ou de sanitaires, n’hésitez pas à nous contacter pour que vos meubles et objets de décoration, soient intégrés à notre `,
            solution_usage_content04_7: `outil d’aménagement de salle de bains 3D.`,
            solution_usage_content05_1: `Autres...`,
            solution_usage_content05_2: `MY3DPLANNER est un`,
            solution_usage_content05_3: `logiciel d’aménagement de de maison, d’appartement, de logement de bureaux`,
            solution_usage_content05_4: `3D gratuit. Grâce à notre large sélection de cloisons, meubles, et finitions, vous pouvez créer très facilement le projet d’aménagement de vos rêves.`,
            solution_usage_content05_5: `Dessinez le plan de votre nouveau logement, terrasse ou jardin et meublez-le en 3D !`,
            solution_usage_content05_6: `Vous pouvez également utiliser MY3DPLANNER pour aménager votre magasin ou boutique les visualiser gratuitement en 3D.`,
            solution_tarif_titre: `TARIFS`,
            solution_tarif_btMensuel: `MENSUEL`,
            solution_tarif_btAnnuel: `ANNUEL`,
            solution_tarif_zone1_1: `Gratuit`,
            solution_tarif_zone1_2a: `0€`,
            solution_tarif_zone1_2b: `0€`,
            solution_tarif_zone1_3a: `/mois`,
            solution_tarif_zone1_3b: `/an`,
            solution_tarif_zone1_4: `2 projets`,
            solution_tarif_zone1_5: `Rendus standards illimités`,
            solution_tarif_zone1_6: `Bibliothèque de meubles 3D`,
            solution_tarif_zone1_7: `Incrustation logo my3dplanner`,
            solution_tarif_zone1_bt: `Connectez-vous`,
            solution_tarif_zone2_1: `Start`,
            solution_tarif_zone2_2a: `3.90€`,
            solution_tarif_zone2_2b: `39€`,
            solution_tarif_zone2_2c: `2 mois offerts pour un engagement annuel`,
            solution_tarif_zone2_4: `4 projets`,
            solution_tarif_zone2_5: `Rendus standards illimités`,
            solution_tarif_zone2_6: `1000 crédits pour les rendus par IA *`,
            solution_tarif_zone2_7: `Support prioritaire`,
            solution_tarif_zone2_8: `Bibliothèque de meubles 3D`,
            solution_tarif_zone2_9: `Incrustation logo my3dplanner`,
            solution_tarif_zone3_1: `Regular`,
            solution_tarif_zone3_2a: `8.90€`,
            solution_tarif_zone3_2b: `89€`,
            solution_tarif_zone3_4: `12 projets`,
            solution_tarif_zone3_5: `Rendus standards illimités`,
            solution_tarif_zone3_6: `2000 crédits pour les rendus par IA *`,
            solution_tarif_zone4_1: `Pro`,
            solution_tarif_zone4_2a: `17.90€`,
            solution_tarif_zone4_2b: `179€`,
            solution_tarif_zone4_4: `50 projets`,
            solution_tarif_zone4_5: `Rendus standards illimités`,
            solution_tarif_zone4_6: `5000 crédits pour les rendus par IA *`,
            solution_tarif_zone5_1: `Entreprise`,
            solution_tarif_zone5_2: `Nous contacter`,
            solution_tarif_zone5_3: `Installation en marque blanche`,
            solution_tarif_zone5_3_1: `Nombre d'utilisateurs illimité`,
            solution_tarif_zone5_4: `Développements spécifiques`,
            solution_tarif_zone5_5: `Intégration de vos modèles 3D et finitions`,
            solution_tarif_zone5_6: `Modélisation de vos modèles 3D`,
            solution_tarif_zone5_bt: `Nous contacter`,
            solution_tarif_ast01: `* Les CRÉDITS`,
            solution_tarif_ast02: `10 crédits pour un rendu 768x512px.`,
            solution_tarif_ast03: `30 crédits pour un rendu 1024x768px.`,
            solution_tarif_ast04: `40 crédits pour un rendu 2048x1536px.`,
            solution_tarif_ast05: ``,
            solution_tarif_ast05old: `** L'usage "illimité" est soumis à certaines conditions raisonnables, afin de garantir une expérience équitable pour tous nos utilisateurs.`,
            contact_titre: "Écrivez nous",
            contact_votreMail: "Votre mail :",
            contact_votreNom: "Votre nom :",
            contact_votreMessage: "Votre message :",
            contact_btEnvoyer: "Envoyer",
            contact_detail_titre: "Coordonnées",
            contact_detail_nom: "CADELOG",
            contact_detail_ad1: "118 rue de Vaugirard",
            contact_detail_ad2: "75006 PARIS",
            contact_detail_mail: "hello@my3dplanner.com",
            contact_detail_tel: "+33 1 85 08 95 05",
            contact_sendLoad:`Merci de patientez...`,
            contact_sendSuccess:`Votre mail a bien été envoyé. Nous traiterons votre demande au plus vite.`,
            contact_sendError:`Un problème est survenu. Merci de rééssayer ou d'utiliser un autre moyen pour nous contacter.`,
            footer_contact: "CONTACT",
            footer_mention: "MENTIONS",
            mention_titre: "Mentions légales et données personnelles",
            mention_sstitre1: "Edition du site",
            mention_content1: `my3dplanner.com est un site édité par la société cadeLOG.
            cadeLOG est une SAS au capital de 6000 euros, dont le siège social est à Paris (75006) 118 rue de Vaugirard, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 488 903 246 (numéro de TVA intracommunautaire FR7488903246).
            Directeur de la publication : Sébastien AMELIN`,
            mention_sstitre2: "Propriété Intellectuelle",
            mention_content2: `Vous pouvez visiter ce site sur Internet sans avoir à décliner votre identité et à fournir des informations personnelles vous concernant`,
            mention_sstitre3: `Modification du site`,
            mention_content3: `L'équipe éditoriale se réserve le droit de modifier ou de corriger le contenu de ce site et de ces mentions légales à tout moment et ceci sans préavis.`,
            mention_sstitre4: `Hébergeur`,
            mention_content4_1: `GANDI SAS,`,
            mention_content4_2: `Société par Actions Simplifiée au capital de 2 300 000€ ayant son siège social au 63-65 boulevard Masséna`,
            mention_content4_3: `Paris (75013)`,
            mention_content4_4: `FRANCE`,
            mention_content4_5: `Siren 423 093 459 RCS PARIS`,
            mention_content4_6: `n. de TVA FR81423093459`,
            mention_content4_7: `Tel +33 (0) 1 70.37.76.61`,
            mention_content4_8: `Fax +33. (0) 1 43 73 18 51`,
            mention_content4_9: `GOOGLE CLOUD PLATFORM - Firebase Hosting,`,
            mention_content4_10: `dont le siège social est situé au Google LLC,`,
            mention_content4_11: `1600 Amphitheatre Parkway,`,
            mention_content4_12: `Mountain View,`,
            mention_content4_13: `California 94043 USA`,
            mention_sstitre5: `Mentions relatives aux cookies`,
            mention_content5: `Un cookie est un fichier texte susceptible d’être enregistré, sous réserve de vos choix, dans un espace dédié du disque dur de votre terminal (ordinateur, smartphone, tablette …) lors de la consultation d’un service en ligne grâce à votre logiciel de navigation.
            Celui-ci est transmis par le serveur du site internet vers votre navigateur. A chaque cookie est attribué un identifiant anonyme. Le fichier cookie permet à son émetteur d’identifier le terminal dans lequel il est enregistré pendant la durée de validité du cookie concerné.
            Lorsque vous consultez le site my3dplanner.com, il est possible que des cookies soient installés pour le bon fonctionnement du site ou, sous réserve de votre choix, des cookies relatifs à la diffusion de publicités sur le site.`,
            mention_sstitre6: `Les types de cookies`,
            mention_sstitre7: `Cookie publicitaires Google AdSense`,
            mention_content7: `Des services tiers de publicités comme la régie Adsense (et ses partenaires) contrôlent des cookies depuis leurs espaces publicitaires. Pour en savoir plus sur les annonces ciblées de Google, cliquez sur le lien suivant : Règles de confidentialité
            Le présent site Internet n’est pas responsable de la gestion et de la durée de vie de ces cookies tiers et vous invite à prendre connaissance de la politique de chacun de ces prestataires.
            Ils ont pour but de vous adresser des publicités personnalisées adaptées à vos attentes. Aucune donnée personnelle telle que vos nom, prénom, adresse postale ou électronique, etc. ne sera transmise à ces tiers partenaires dont l’intervention sur le site web se limite au dépôt de cookie par le biais des contenus publicitaires qu’ils gèrent.
            Le présent site Web et n’a aucun contrôle sur ces cookies.`,
            mention_sstitre8: `Cookies de Statistiques Google Analytics`,
            mention_content8: `Ces cookies permettent d’établir des statistiques de fréquentation des sites my3dplanner.com et de détecter des problèmes de navigation afin de suivre et d’améliorer la qualité de nos services.
            Choix liés aux cookies dans le navigateur
            Vous pouvez à tout moment paramétrer votre navigateur afin de modifier la façon dont les cookies seront enregistrés ou non sur votre terminal. Vous pouvez exprimer vos choix en paramétrant votre navigateur de façon à refuser certains cookies.
            Si vous refusez nos cookies et ceux de nos partenaires, vos visites des sites my3dplanner.com ne seront plus comptabilisées dans Google Analytics et il est possible que certaines fonctionnalités pourraient ne plus fonctionner correctement ou certains services plus accessibles dans le cas de refus des cookies.
            Pour en savoir plus sur les cookies, nous vous invitons à consulter la  page de la CNIL à ce propos.`,
            mention_sstitre9: `Politique de Confidentialité`,
            mention_sstitre10: `Formulaires de contact`,
            mention_content10: `Les informations envoyées via un formulaire de contact sont conservées pendant une période indéterminée pour les questions liées à la demande du visiteur ayant contacté le site, mais ne seront jamais utilisées à d’autres fins (aucune utilisation commerciale ou autre).`,
            mention_sstitre11: `Formulaires d’inscription à l’application`,
            mention_content11: `Votre mail sera obligatoire pour la création d’un compte utilisateur. L’accès aux données personnelles est strictement limité aux personnel de cadeLOG habilités à les traiter en raison de leurs fonctions, et à nos éventuels sous-traitants informatique. Les sous-traitants en question sont soumis à une obligation de confidentialité et ne peuvent utiliser vos données qu’en conformité avec nos dispositions contractuelles et la législation applicable. Nous nous engageons à ne pas vendre, louer, céder ni donner accès à des tiers à vos données sans votre consentement préalable, à moins d’y être contraints en raison d’un motif légitime (obligation légale, lutte contre la fraude ou l’abus, exercice de droits de la défense, etc.).`,
            mention_sstitre12: `Protection des informations`,
            mention_content12: `Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous protégeons également vos informations hors ligne. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.
            N’hésitez pas à nous contacter pour en savoir plus.`,
            cgv_link: "CGV",
            cgv_titre: 'Conditions générales de vente',
            cgv_sstitre1: 'Objet du contrat',
            cgv_content11: 'Les présentes CGV régissent les conditions d\'utilisation et de vente des services proposés par les sites my3dplanner.com, 3dplanner.app et leurs sous domaines.',
            cgv_content12: 'En utilisant nos services, vous acceptez d\'être lié par ces conditions.',
            cgv_sstitre2: 'Description des services',
            cgv_content21: 'Notre site offre un service de conception et de planification d\'intérieurs virtuels.',
            cgv_content22: 'Vous pouvez créer des projets, personnaliser des espaces, ajouter des meubles et visualiser votre conception en 3D.',
            cgv_sstitre3: 'Utilisation du service',
            cgv_content31: 'En utilisant notre service, vous vous engagez à respecter nos conditions d\'utilisation.',
            cgv_content32: 'Vous ne pouvez pas utiliser nos services à des fins illégales, frauduleuses ou qui enfreignent les droits de tiers.',
            cgv_content33: 'Vous êtes responsable du contenu que vous créez et partagez sur notre site.',
            cgv_sstitre4: 'Tarifs et paiement',
            cgv_content41: 'Les tarifs de nos services sont indiqués sur notre site et peuvent être modifiés à tout moment sans préavis.',
            cgv_content42: 'Les paiements sont effectués en ligne par les moyens de paiement acceptés sur notre plateforme.',
            cgv_content43: 'Les paiements sont non remboursables, sauf en cas de problème technique majeur imputable à notre site.',
            cgv_sstitre5: 'Propriété intellectuelle',
            cgv_content51: 'Vous conservez les droits de propriété intellectuelle sur le contenu que vous créez et partagez sur notre site.',
            cgv_content52: 'Cependant, en utilisant nos services, vous nous accordez une licence non exclusive, mondiale et gratuite pour utiliser, reproduire, modifier et distribuer ce contenu dans le but de fournir nos services.',
            cgv_sstitre6: 'Confidentialité',
            cgv_content61: 'Nous respectons votre vie privée et protégeons vos données personnelles conformément à notre politique de confidentialité.',
            cgv_content62: 'Nous ne vendrons ni ne partagerons vos informations personnelles à des tiers sans votre consentement.',
            cgv_sstitre7: 'Limitation de responsabilité',
            cgv_content71: 'Nous nous efforçons de fournir un service de haute qualité, mais nous ne pouvons garantir l\'exactitude, la fiabilité ou l\'exhaustivité de nos services.',
            cgv_content72: 'Nous déclinons toute responsabilité en cas de dommages directs, indirects, accessoires, spéciaux ou consécutifs découlant de l\'utilisation de nos services.',
            cgv_sstitre8: 'Modification des CGV',
            cgv_content81: 'Nous nous réservons le droit de modifier ces CGV à tout moment. Les modifications prendront effet dès leur publication sur notre site. Il est de votre responsabilité de consulter régulièrement les CGV mises à jour.',
            cgv_sstitre9: 'Droit applicable et juridiction compétente',
            cgv_content91: 'Ces CGV sont régies conformément au droit français. En cas de litige, les tribunaux compétents seront ceux du ressort de Paris.',
            cgv_content10: 'N’hésitez pas à nous contacter pour en savoir plus.',
            home_tileFaq: "QUESTIONS FRÉQUENTES (FAQ)",
            home_video_titre: `DÉCOUVRIR EN VIDÉO`,
            solution_tarif_more: `Pour plus de crédits, nous contacter`,
        }
    }
}
const i18n = createI18n({
    // legacy: false,
    locale: 'fr', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages
})

export default i18n