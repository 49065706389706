<script>
import AppBanner from '@/components/shared/AppBanner';
// import ProjectsGrid from '../components/projects/ProjectsGrid.vue';
import My3dpPresentation from '../components/presentation/My3dpPresentation.vue';
import My3dpConcept from '../components/presentation/My3dpConcept.vue';
import My3dpPersonnalisation from '../components/presentation/My3dpPersonnalisation.vue';
import My3dpFaq from '../components/presentation/My3dpFaq.vue';
import My3dpCarousel from '../components/presentation/My3dpCarousel.vue';
import My3dpVideo from '../components/presentation/My3dpVideo.vue';
// import Button from '../components/reusable/Button.vue';
// import { ref } from 'vue';

export default {
	name: 'Home',
	components: {
		AppBanner,
		// ProjectsGrid,
		My3dpPresentation,
		My3dpConcept,
		My3dpPersonnalisation,
		My3dpFaq,
		My3dpCarousel,
		My3dpVideo
	},
	mounted() {
		// const itemsActive = ref([]);
		if ( this.$i18n.locale === 'en' ) {
			
			document.title = 'Free online 2D/3D interior design software'
			document.getElementsByTagName('meta')["description"].content = 'MY3DPLANNER is online architecture software. Quickly design, furnish, personalize and visualize your projects, directly in your browser.';
		}else{
			
			document.title = 'Logiciel d\'aménagement intérieur 2D/3D en ligne gratuit'
			document.getElementsByTagName('meta')["description"].content = 'MY3DPLANNER est un logiciel d\'architecture en ligne. Concevez, meublez, personnalisez et visualisez rapidement vos projets, directement dans votre navigateur.';
		}
	},
	data: () => ({
		myItems: [
			{
				title: `Comment décrire MY3DPLANNER ?`,
				value: `MY3DPLANNER est un outil conçu pour dessiner facilement et rapidement vos plans 2D/3D. Il est accessible depuis un navigateur internet. Il permet de construire des environnements 3D, de les mettre à jour et de les exporter à l'aide d'une interface web élaborée. Aucun téléchargement n'est requis pour utiliser MY3DPLANNER.`,
				titleEn: "How to describe MY3DPLANNER?",
				valueEn: "MY3DPLANNER is a tool designed for easy and quick 2D/3D drawing. It is accessible through a web browser, 	allowing you to construct 3D environments, update them, and export using a sophisticated web interface. No download is 	required to use MY3DPLANNER."
			},
			{
				title: `Pourquoi utiliser MY3DPLANNER ?`,
				value: `MY3DPLANNER permet de dessiner facilement vos plans sur tous supports. Il 
				centralise vos projets 3D à un seul endroit. Il donne vie à vos projets et vous offre une 
				visualisation 3D photoréaliste facilement et rapidement. C'est un également un outil 
				d'aide à la vente qui être installé en marque blanche et connecté à vos applications 
				métier.`,
				titleEn: `Why use MY3DPLANNER?`,
valueEn: `MY3DPLANNER makes drawing plans easy on any device, centralizing your 3D projects in one place. It brings your projects to life, providing a quick and easy photorealistic 3D visualization. It is also a sales tool that can be white-labeled and integrated with your business applications.`
			},
			{
				title: `Comment utiliser MY3DPLANNER ?`,
				value: `MY3DPLANNER s'utilise depuis un navigateur internet. Il est accessible depuis un téléphone, une tablette ou un oridinateur PC, Mac ou Linux. L'utilisation de MY3DPLANNER s'articule autour d'une bibliothèque de meubles et d'accessoires, d'une zone dédiée à la construction et à la modélisation 3D et d'une partie regroupant les actions comme la sauvegarde, l'export et l'import des projets`,
				titleEn: `How to use MY3DPLANNER?`,
valueEn: `MY3DPLANNER is used through a web browser, accessible from a phone, tablet, or PC, Mac, or Linux computer. It revolves around a library of furniture and accessories, a dedicated area for 3D construction and modeling, and a section for actions such as saving, exporting, and importing projects.`
			},
			
			{
				title: `Quelles sont les principales fonctionnalités du logiciel ?`,
				value: `MY3DPLANNER vous permet de dessiner des plans 2D et 3D et de les visualiser en 3D. 
				MY3DPLANNER permet également de meubler, décorer et chiffrer vos projets. Une fois 
				terminé, partagez vos projets, exportez en 3D ou générez des rendus photoréalistes grâce à 
				l'IA.`,
				titleEn: `What are the main features of the software?`,
valueEn: `MY3DPLANNER allows you to draw 2D and 3D plans and view them in 3D. It also facilitates furnishing, decorating, and costing your projects. Once completed, share your projects, export in 3D, or generate photorealistic renders using AI.`
			},
			{
				title: `A quoi sert l'option créer un visuel IA ?`,
				value: `Le visuel IA permet de créer des rendus photoréalistes. Ces rendus sont optimisés 
				par une intelligence artificielle qui s'adapte en fonction des critères choisis.`,
				titleEn: `What is the purpose of the 'Create AI Visual' option?`,
valueEn: `The AI Visual option creates photorealistic renders optimized by artificial intelligence, adapting to chosen criteria.`
			},
			{
				title: `Combien de temps faut-il avant de maîtriser MY3DPLANNER ?`,
				value: `MY3DPLANNER a été conçu pour être simple d'utilisation. Dès les premiers projets 
				vous commencerez à maitriser l'outil. L'apprentissage se fait en grande partie avec la 
				pratique, plus vous passerez de temps à concevoir des projets plus vous monterez en 
				compétence.`,
				titleEn: `How long does it take to master MY3DPLANNER?`,
valueEn: `MY3DPLANNER is designed to be user-friendly. You'll start mastering the tool with your first projects. Learning primarily occurs through practice; the more time you spend designing projects, the more proficient you'll become.`
			},
			
			{
				title: `Peut-on installer MY3DPLANNER en marque blanche ?`,
				value: `L'application MY3DPLANNER peut être installée en marque blanche. Cela vous permet de personnaliser la charte graphique, les bitliothèques de meubles et de finitions, le chiffrage et toutes autres fonctionnalités spécifiques. Notre objectif est de vous fournir une solution sur mesure qui répond parfaitement à vos attentes. N'hésitez pas à nous écrire, et nous travaillerons ensemble pour créer une expérience unique et adaptée à vos besoins.`,
				titleEn: `Can MY3DPLANNER be white-labeled?`,
valueEn: `The MY3DPLANNER application can be white-labeled, allowing you to customize the visual identity, furniture and finishing libraries, costing, and other specific features. Our goal is to provide a tailored solution that perfectly meets your expectations. Feel free to contact us, and we'll work together to create a unique and tailored experience for your needs.`
			},
			{
				title: `Peut on connecter MY3DPLANNER avec d'autres logiciels ?`,
				value: `Pour créer votre liste de produits, afficher des chiffrages actualisés ou bien 
				pour exporter des images photoréalistes et des fichiers 3D, MY3DPLANNER permet des 
				connexions avec d'autres applications. Pour plus d'informations contactez nous.`,
				titleEn: `Can MY3DPLANNER be connected to other software?`,
valueEn: `For creating product lists, displaying updated cost estimates, or exporting photorealistic images and 3D files, MY3DPLANNER supports connections with other applications. For more information, please contact us.`
			},
			{
				title: `Est ce qu’on peut utiliser MY3DPLANNER gratuitement ?`,
				value: `MY3DPLANNER est accessible gratuitement dans la limite de 2 projets par utilisateur. Si vous souhaitez créer plus de projets, exporter ou générer des images avec l'IA, il faudra prendre au minimum un abonnement START ou supérieur.`,
				titleEn: `Is MY3DPLANNER free to use?`,
valueEn: `MY3DPLANNER is accessible for free, limited to 2 projects per user. To create more projects, export, or generate images with AI, you need to subscribe to at least a START or higher plan.`
			},
			{
				title: `A quoi servent les crédits ?`,
				value: `Les crédits servent à réaliser de rendus photoréalistes avec l'IA. Ils sont fournis lors de la souscription à un abonnement.`,
				titleEn: `What are credits used for?`,
valueEn: `Credits are used to create photorealistic renders with AI and are provided upon subscribing to a plan.`
			},
			{
				title: `Y a-t-il une durée minimale d'engagement ?`,
				value: `L'outil MY3DPLANNER est sans durée minimale d'engagement. Les abonnements correspondent à la période choisie à la souscription.`,
				titleEn: `Is there a minimum commitment period?`,
valueEn: `MY3DPLANNER has no minimum commitment period. Subscriptions correspond to the chosen period at the time of subscription.`
			},
			{
				title: `Dans quelles langues est disponible le logiciel MY3DPLANNER ?`,
				value: `MY3DPLANNER est disponible en français et en anglais.`,
				titleEn: `In which languages is MY3DPLANNER available?`,
valueEn: `MY3DPLANNER is available in French and English.`
			}
		]
	})
};
</script>

<template>
	<!-- <div class="w-full bg-my3dp-darkBlue"> -->
	<!-- <div class="container mx-auto"> -->
	<div>
		
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" />

		<!-- Presentation -->
		<!-- <div class="w-full bg-my3dp-Blue"> -->
		<My3dpPresentation />
		<My3dpCarousel />
		
		<!-- </div> -->
		<!-- Concept -->
		<My3dpConcept />
		<My3dpVideo />
		
		<!-- Personnalisation -->
		<My3dpPersonnalisation />

		<My3dpFaq :items='myItems'/>
		<!-- <accordion /> -->
		<!-- <My3dpPersonnalisation /> -->
		<!-- Projects -->
		<!-- <ProjectsGrid /> -->

		<!-- Load more projects button -->
		<!-- <div class="mt-10 sm:mt-20 flex justify-center"> -->
			<!-- <router-link -->
				<!-- to="/projects" -->
				<!-- class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300" -->
				<!-- aria-label="More Projects" -->
			<!-- > -->
				<!-- <Button title="More Projects" /> -->
			<!-- </router-link> -->
		<!-- </div> -->
	</div>
	<!-- </div> -->
</template>

<style scoped></style>
